import React from "react";
import { getRatingInStars } from "../../../utils/Establishment";

const EstablishmentHeader = ({ name, rating }) => {
  return (
    <div className="w-full pt-5 pb-5 mb-5">
      <div className="flex justify-between items-center container mx-auto">
        <h1 className="text-roca-h4-5">{name}</h1>
        {rating && (
          <div className="flex items-center gap-5">
            <p className="text-roca-h6">{rating} / 5</p>
            <div className="flex space-x-1">{getRatingInStars(rating)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EstablishmentHeader;
