import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

const CardAction = ({
  label,
  navToLink,
  icon,
  additionalClassname = "",
  establishmentId,
  disabled = false,
}) => {
  const navigate = useNavigate();

  const handleNavTo = () => {
    if (!disabled) {
      navigate(`/dashboard/${establishmentId}/action/${navToLink}`);
    }
  };

  return (
    <div
      onClick={handleNavTo}
      className={`bg-quinaire-600 text-poppins-small bg-opacity-10 border border-quinaire-600 rounded-20 flex gap-3 justify-center items-center p-6 ${
        disabled ? "cursor-not-allowed opacity-50" : "hover:cursor-pointer"
      } ${additionalClassname}`}
    >
      <FontAwesomeIcon
        icon={icon}
        className={`text-primary-600 ${disabled ? "opacity-50" : ""}`}
      />
      <h1
        className={`text-poppins-small text-center ${
          disabled ? "opacity-50" : ""
        }`}
      >
        {label}
      </h1>
    </div>
  );
};

export default CardAction;
