import React from "react";

const CardProPart = ({ text, onClick }) => {
  return (
    <div
      className="w-[150px] h-[150px] bg-secondary-300  flex items-center justify-center cursor-pointer rounded-16 pl-[22px] pr-[22px] "
      onClick={onClick}
    >
      <p className="text-poppins-paragraph !text-primary-600">{text}</p>
    </div>
  );
};

export default CardProPart;
