import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Input from "./Input";

const InputPrice = ({ value, onChange }) => {
  const [price, setPrice] = useState(value || 0); // Valeur par défaut du prix
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null); // Référence pour le menu déroulant

  const handlePriceChange = (event) => {
    const newPrice = event.target.value;
    setPrice(newPrice);
    onChange(newPrice);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Inverser l'état d'ouverture du dropdown
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      <div
        className="flex items-center justify-between cursor-pointer w-40 bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
        onClick={toggleDropdown}
      >
        <p>Prix</p>
        <FontAwesomeIcon icon={faChevronDown} className="text-gray-600" />
      </div>

      {isDropdownOpen && (
        <div ref={dropdownRef} className="mt-4 absolute bg-white p-5 rounded-8">
          <div className="flex justify-between">
            <span>0 €</span>
            <span>{price} €</span>
          </div>

          {/* Slider */}
          <input
            type="range"
            min="0"
            max="150"
            value={price}
            onChange={handlePriceChange}
            className="slider w-full mt-2 "
          />

          {/* Input number field for manual entry */}
          <Input
            type="number"
            min="0"
            max="150"
            value={price}
            onChange={handlePriceChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="Entrez un prix"
          />
        </div>
      )}
    </div>
  );
};

export default InputPrice;
