import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createReview = async (reviewData, establishmentId) => {
  const res = await axios.post(
    `${API_URL}/reviews/send-review/${establishmentId}`,
    reviewData
  );

  if (!res) return;
  return res;
};

export const getReviewsByEstablishmentId = async (establishmentId) => {
  const res = await axios.get(`${API_URL}/reviews/${establishmentId}`);
  if (!res) return;
  return res.data;
};

export const reportReview = async (reviewId) => {
  const res = await axios.post(`${API_URL}/reviews/report-review/${reviewId}`);
  if (!res) return;
  return res;
};

export const deleteReview = async (reviewId) => {
  const res = await axios.delete(
    `${API_URL}/reviews/delete-answer/${reviewId}`
  );
  if (!res) return;
  return res;
};

export const updateReview = async (reviewId, reviewData) => {
  const res = await axios.patch(
    `${API_URL}/reviews/update-answer/${reviewId}`,
    reviewData
  );
  if (!res) return;
  return res;
};

export const likeReview = async (reviewId) => {
  const res = await axios.post(`${API_URL}/reviews/like-review/${reviewId}`);
  if (!res) return;
  return res;
};

export const removeAnswer = async (reviewId) => {
  const res = await axios.patch(`${API_URL}/reviews/remove-answer/${reviewId}`);
  if (!res) return;
  return res;
};

export const answerReview = async (reviewId, answer) => {
  const res = await axios.patch(
    `${API_URL}/reviews/answer-review/${reviewId}`,
    { comment: answer }
  );
  if (!res) return;
  return res.data;
};

export const updateAnswer = async (reviewId, answer) => {
  const res = await axios.patch(`${API_URL}/reviews/update-answer/${reviewId}`, {
    comment: answer,
  });
  if (!res) return;
  return res.data;
};
