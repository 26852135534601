import React, { useState, useEffect } from "react";
import { predefinedTerms } from "../../../utils/predefinedTerms";
import { searchEstablishments } from "../../../services/establishements.services";
import SelectFilter from "../../elements/select/SelectFilter";
import InputPrice from "../../elements/Input/InputPrice";
import InputEcoNote from "../../elements/Input/InputEcoNote";
import InputKm from "../../elements/Input/InputKm";

const FilterDropdown = ({ onResultsUpdate, currentCity }) => {
  const [filters, setFilters] = useState({
    label: "",
    type: "",
    price: "",
    ecoNote: "",
    distance: 10000,
  });

  const [prevFilters, setPrevFilters] = useState(filters);

  useEffect(() => {
    const filtersChanged =
      JSON.stringify(filters) !== JSON.stringify(prevFilters);

    if (filtersChanged) {
      const fetchEstablishments = async () => {
        try {
          const lat = currentCity.lat;
          const lon = currentCity.lon;
          const searchTerm = "";

          const results = await searchEstablishments(
            searchTerm,
            lat,
            lon,
            filters.distance,
            {
              price: filters.price,
              ecoNote: filters.ecoNote,
              type: filters.type,
              labels: filters.label ? [filters.label] : [],
            }
          );

          onResultsUpdate(results);
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des établissements :",
            error
          );
        }
      };

      fetchEstablishments();
      setPrevFilters(filters);
    }
  }, [filters, prevFilters, onResultsUpdate, currentCity.lat, currentCity.lon]);

  const handleFilterChange = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: value,
    }));
  };

  return (
    <div className="container mx-auto flex flex-row mt-5 justify-center items-center gap-8 mb-5">
      <div className="filter-section">
        <SelectFilter
          value={filters.label}
          onChange={(value) => handleFilterChange("label", value)}
          options={predefinedTerms.filter((term) => term.category === "labels")}
          placeholder="Tous les labels"
        />
      </div>

      <div className="filter-section">
        <SelectFilter
          value={filters.type}
          onChange={(value) => handleFilterChange("type", value)}
          options={predefinedTerms.filter(
            (term) => term.category === "type de cuisine"
          )}
          placeholder="Tous les types"
        />
      </div>

      <div className="filter-section">
        <InputPrice
          value={filters.price}
          onChange={(value) => handleFilterChange("price", value)}
        />
      </div>

      <div className="filter-section">
        <InputEcoNote
          value={filters.ecoNote}
          onChange={(value) => handleFilterChange("ecoNote", value)}
        />
      </div>

      <div className="filter-section">
        <InputKm
          value={filters.distance}
          onChange={(value) => handleFilterChange("distance", value)}
        ></InputKm>
      </div>
    </div>
  );
};

export default FilterDropdown;
