import React from "react";
import team1 from "../../assets/team1.svg";
import team2 from "../../assets/team2.svg";
import team3 from "../../assets/team3.svg";
import team4 from "../../assets/team4.svg";
import team5 from "../../assets/team5.svg";
import team6 from "../../assets/team6.svg";

const Teams = () => {
  return (
    <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6 h-screen">
      <div className="w-full max-w-6xl mb-12">
        <p className="text-poppins-extra-small mb-2">A propos de nous</p>
        <h1 className="text-roca-h3 ">Notre équipe</h1>
      </div>

      <div className="grid justify-items-center  grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl bg-white rounded-20 shadow-xl p-12">
        <div className="flex flex-col justify-center items-center">
          <img src={team1} alt="" className="mb-8" />
          <h2 className="text-poppins-h6">Altynai Zhusupova</h2>
          <p className="text-poppins-small">Chef de Projet, UX/UI</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={team2} alt="" className="mb-8" />
          <h2 className="text-poppins-h6">Charlotte Rose-Verdier</h2>
          <p className="text-poppins-small">Chef de projet, DA</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={team3} alt="" className="mb-8" />
          <h2 className="text-poppins-h6">Cristy Fundu Zanza</h2>
          <p className="text-poppins-small">Marketing</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={team4} alt="" className="mb-8" />
          <h2 className="text-poppins-h6">Zainab Elfallah</h2>
          <p className="text-poppins-small">Marketing</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={team5} alt="" className="mb-8" />
          <h2 className="text-poppins-h6">Guéwen Carré</h2>
          <p className="text-poppins-small">Développeur</p>
        </div>
        <div className="flex flex-col justify-center items-center">
          <img src={team6} alt="" className="mb-8" />
          <h2 className="text-poppins-h6">Beverly David</h2>
          <p className="text-poppins-small">DA</p>
        </div>
      </div>
    </div>
  );
};

export default Teams;
