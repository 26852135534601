import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom"; // Utilisation de useNavigate pour la navigation

const WhatIsTheAction = ({ name, actionIs }) => {
  const navigate = useNavigate(); // Hook pour naviguer avec React Router

  // Liste des actions possibles
  const actions = {
    requestLabel: "Demander un label pour ",
    showFiles: "Voir les fichiers de ",
    editMyEstablishement: "Modifier les informations pour ",
    followRequest: "Suivre mes demandes pour ",
    showReviewsEstablishement: "Voir les avis de ",
  };

  const action = actions[actionIs] || "Action inconnue pour ";

  return (
    <div className="w-full h-[80px]  flex items-center">
      <div className="container mx-auto flex items-center">
        <div className="cursor-pointer p-2 " onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </div>
        <p className="text-poppins-small ml-4 text-gray-700">
          {action}
          <span className="font-bold">{name}</span>
        </p>
      </div>
    </div>
  );
};

export default WhatIsTheAction;
