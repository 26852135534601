import React from "react";
import imageApp from "../../assets/ImageApp.svg";
import Button from "../../components/elements/Button/Button";

const DiscoverApp = () => {
  return (
    <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6 h-screen">
      <div className="w-full max-w-6xl mb-12">
        <p className="text-poppins-extra-small mb-2">
          Restaurants à proproximité
        </p>
        <h1 className="text-roca-h3 !text-secondary-600 ">
          Découvrez LabelAdresse, votre guide d'éco-responsabilité tout-en-un
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-6xl items-center">
        <div className="flex flex-col">
          <div className="flex justify-center flex-col items-center bg-white rounded-20 p-12 ">
            <p className="text-poppins-paragraph mb-5">
              Plus besoin de se prendre la tête pour choisir un restaurant qui
              est aligné avec vos valeurs, avec LabelAdresse, retrouvez:
            </p>
            <ul className="text-poppins-small list-disc">
              <li className="">
                Des restaurants qui réunissent toutes vos valeurs
              </li>
              <li>Les critères d'un lieu véritablement éco-responsable</li>
              <li>
                Des avis constructifs pour que votre lieu préféré s'épanouisse
              </li>
            </ul>
          </div>
          <div className="flex items-end justify-end mt-5">
            <Button colorType="secondary">Télécharger l'application</Button>
          </div>
        </div>

        <div className="flex justify-center flex-col items-center">
          <img src={imageApp} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DiscoverApp;
