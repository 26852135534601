import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Créer un label
export const createLabel = async (labelData) => {
  try {
    const response = await axios.post(`${API_URL}/labels`, labelData);
    return response.data;
  } catch (error) {
    console.error("Error creating label:", error);
    throw error;
  }
};

// Obtenir un label par ID
export const getLabelById = async (labelId) => {
  try {
    const response = await axios.get(`${API_URL}/labels/${labelId}`);
    return response.data;
  } catch (error) {
    console.error("Error getting label by ID:", error);
    throw error;
  }
};

// Mettre à jour un label par ID
export const updateLabelById = async (labelId, labelData) => {
  try {
    const response = await axios.patch(`${API_URL}/labels/${labelId}`, labelData);
    return response.data;
  } catch (error) {
    console.error("Error updating label by ID:", error);
    throw error;
  }
};

// Obtenir tous les labels
export const getAllLabels = async () => {
  try {
    const response = await axios.get(`${API_URL}/labels`);
    return response.data;
  } catch (error) {
    console.error("Error getting all labels:", error);
    throw error;
  }
};

// Supprimer un label par ID
export const deleteLabelById = async (labelId) => {
  try {
    const response = await axios.delete(`${API_URL}/labels/${labelId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting label by ID:", error);
    throw error;
  }
};
