import React, { useEffect, useRef, useState } from "react";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "./Input";

const InputEcoNote = ({ value, onChange }) => {
  const [ecoNote, setEcoNote] = useState(value || 0); // Valeur par défaut de la note
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null); // Référence pour le menu déroulant

  const handleEcoNoteChange = (event) => {
    const newEcoNote = event.target.value;
    setEcoNote(newEcoNote);
    onChange(newEcoNote); // Passe la nouvelle valeur de la note au parent
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Inverser l'état d'ouverture du dropdown
  };

  // Gestion des clics en dehors du composant pour fermer le dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false); // Fermer le dropdown si clic en dehors
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      <div
        className="flex items-center justify-between cursor-pointer w-40 bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
        onClick={toggleDropdown}
      >
        <p>EcoNote</p>
        <FontAwesomeIcon icon={faChevronDown} className="text-gray-600" />
      </div>

      {isDropdownOpen && (
        <div ref={dropdownRef} className="mt-4 absolute bg-white p-5 rounded-8">
            <p className="text-poppins-small p-1">Note minium</p>
          <Input
            type="number"
            min="0"
            max="5"
            value={ecoNote}
            onChange={handleEcoNoteChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="Entrez une note écologique"
          />
        </div>
      )}
    </div>
  );
};

export default InputEcoNote;
