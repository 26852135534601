import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = process.env.REACT_APP_API_URL_LOCAL;


// Function to create a new category
export const createCategory = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/faq/category/create`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating category:", error);
    throw error;
  }
};

// Function to get all categories and their questions
export const getAllCategoriesAndQuestions = async () => {
  try {
    const response = await axios.get(`${API_URL}/faq/all-categories`);
    return response.data;
  } catch (error) {
    console.error("Error fetching categories and questions:", error);
    throw error;
  }
};

// Function to add a question to a category
export const addQuestionInCategory = async (categoryId, data) => {
  try {
    const response = await axios.post(
      `${API_URL}/faq/category/${categoryId}/add-question`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error adding question:", error);
    throw error;
  }
};

// Function to get all questions in a specific category
export const getQuestionsInCategory = async (categoryId) => {
  try {
    const response = await axios.get(
      `${API_URL}/faq/category/${categoryId}/questions`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching questions:", error);
    throw error;
  }
};

// Function to get a specific question by its ID
export const getQuestionById = async (questionId) => {
  try {
    const response = await axios.get(`${API_URL}/faq/question/${questionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching question:", error);
    throw error;
  }
};

// Function to update a specific question by its ID
export const updateQuestion = async (questionId, data) => {
  try {
    const response = await axios.put(
      `${API_URL}/faq/question/${questionId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error updating question:", error);
    throw error;
  }
};

// Function to delete a specific question by its ID
export const deleteQuestion = async (questionId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/faq/question/${questionId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting question:", error);
    throw error;
  }
};

// Function to update a specific category by its ID
export const updateCategory = async (categoryId, data) => {
  try {
    const response = await axios.put(
      `${API_URL}/faq/category/${categoryId}`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Error updating category:", error);
    throw error;
  }
};

// Function to delete a specific category by its ID
export const deleteCategory = async (categoryId) => {
  try {
    const response = await axios.delete(
      `${API_URL}/faq/category/${categoryId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting category:", error);
    throw error;
  }
};

// Function to get all categories
export const getAllCategories = async () => {
  try {
    const response = await axios.get(`${API_URL}/faq/categories`);
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};
