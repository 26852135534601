import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const API_URL = process.env.REACT_APP_API_URL_LOCAL;


export const sendFormContact = async (formData) => {
  try {
    const response = await axios.post(
      `${API_URL}/contact/contact-form`,
      formData
    );

    return response.data;
  } catch (error) {
    console.error("Error sending contact form:", error);
    throw error;
  }
};
