import React from "react";

const ProStepThree = () => {
  return (
    <div className="w-full mt-8 pl-[65px] pr-[65px]">
      <p className="text-center text-poppins-paragraph ">
        <span className="font-bold">Excellente nouvelle !</span> <br></br> Vous
        venez de recevoir un courriel de confirmation. Prenez quelques instants
        pour vérifier votre e-mail, et vous serez prêt à plonger dans
        l'application et à commencer à l'explorer !
      </p>
    </div>
  );
};

export default ProStepThree;
