import React, { useState } from "react";

const MenuEstablishment = ({ menu }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
      <h2 className="text-roca-h6 pb-3">Menu</h2>
      <div className="flex flex-wrap gap-4">
      
        {menu?.files?.map((file, index) => (
          <div key={index} className="flex flex-col items-center">
            <img
              src={file.fileUrl}
              alt={file.filename}
              className="w-32 h-32 object-cover rounded-lg cursor-pointer"
              onClick={() => handleImageClick(file.fileUrl)}
            />
            <p className="text-sm mt-2">{file.filename}</p>
          </div>
        ))}
      </div>

      {selectedImage && (
        <div
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50"
          onClick={handleCloseModal}
        >
          <div className="relative">
            <img
              src={selectedImage}
              alt="Selected"
              className="max-w-full max-h-full rounded-lg"
            />
            <button
              className="absolute top-2 right-2 text-white bg-gray-800 p-2 rounded-full"
              onClick={handleCloseModal}
            >
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuEstablishment;
