export const getCategorie = (categorie) => {
  switch (categorie) {
    case "establishment":
      return {
        name: "Photo de l'établissement",
      };

    case "menu":
      return {
        name: "Menu",
      };

    case "invoice":
      return {
        name: "Facture",
      };

    case "contract":
      return {
        name: "Contrat",
      };

    case "report":
      return {
        name: "Rapport",
      };

    default:
      return {
        name: "Autre",
      };
  }
};
