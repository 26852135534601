import React, { useState } from "react";
import { Link } from "react-router-dom";
import Button from "../elements/Button/Button";
import NavBarLogo from "../../assets/NavBarLogo.png";
import { useNavigationService } from "../../services/navigation/navigation.services";
import { useAuth } from "../Auth/Provider/AuthProvider";
import Avatar from "./Avatar";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { navigateToLogin } = useNavigationService();
  const { user } = useAuth();
  const roleUser = user?.role;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuLinks = {
    auditor: [
      { to: "/", label: "Accueil" },
      { to: "/my-requests", label: "Mes Demandes" },
      { to: "/all-requests", label: "Toutes les Demandes" },
    ],
    owner: [
      { to: "/", label: "Accueil" },
      { to: "/my-establishements", label: "Mes Restaurants" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts-pro", label: "Contacts" },
    ],
    admin: [{ to: "/dashboard", label: "Dashboard" }],
    user: [
      { to: "/", label: "Accueil" },
      // { to: "/about", label: "À propos" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts", label: "Contacts" },
    ],
    default: [
      { to: "/", label: "Accueil" },
      // { to: "/about", label: "À propos" },
      { to: "/labels", label: "Labels" },
      { to: "/contacts", label: "Contacts" },
    ],
  };

  const renderMenuLinks = () => {
    const links = menuLinks[roleUser] || menuLinks.default;
    return links.map((link) => (
      <Link key={link.to} to={link.to} className="hover:text-primary-700">
        {link.label}
      </Link>
    ));
  };

  const renderAvatar = (initial) => <Avatar />;

  const renderButtons = () => {
    if (roleUser) {
      return roleUser === "user" ? (
        <div className="flex space-x-4 items-center">
          <Button colorType="white_border">TÉLÉCHARGER L’APPLICATION</Button>
          {renderAvatar()}
        </div>
      ) : (
        renderAvatar()
      );
    } else {
      return (
        <div className="flex space-x-4">
          <Button colorType="white_border">TÉLÉCHARGER L’APPLICATION</Button>
          <Button colorType="primary" onClick={navigateToLogin}>
            CONNEXION
          </Button>
        </div>
      );
    }
  };

  return (
    <div className="bg-white shadow-md w-full">
      <div className="flex justify-between items-center px-4 py-2 h-[125.25px]">
        {/* Logo */}
        <div className="flex items-center w-[200px] h-[60px] ml-5">
          <img src={NavBarLogo} alt="Label Adresse" className="h-full w-full" />
        </div>

        {/* Menu Burger Icon for Mobile */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className="outline-none mobile-menu-button"
          >
            <svg
              className="w-6 h-6 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
              ></path>
            </svg>
          </button>
        </div>

        {/* Navigation Links - Hidden on Mobile */}
        <div className="hidden md:flex gap-24 text-primary-600 font-medium">
          {renderMenuLinks()}
        </div>

        {/* Buttons or Avatar */}
        <div className="hidden md:flex">{renderButtons()}</div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="flex flex-col gap-4 text-primary-600 font-medium p-4">
            {renderMenuLinks()}
            {renderButtons()}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavBar;
