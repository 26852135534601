import React, { useMemo } from "react";

const RatingSummary = ({ reviews }) => {
  const averageRating = useMemo(() => {
    const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
    return totalRating / reviews.length || 0;
  }, [reviews]);

  const distribution = useMemo(() => {
    const dist = { 5: 0, 4: 0, 3: 0, 2: 0, 1: 0 };
    reviews.forEach((review) => {
      if (review.rating >= 5) dist[5] += 1;
      else if (review.rating >= 4) dist[4] += 1;
      else if (review.rating >= 3) dist[3] += 1;
      else if (review.rating >= 2) dist[2] += 1;
      else dist[1] += 1;
    });
    Object.keys(dist).forEach((key) => {
      dist[key] = (dist[key] / reviews.length) * 100;
    });
    return dist;
  }, [reviews]);

  const strokeValue = (averageRating / 5) * 100;

  return (
    <div className="p-6 flex items-center space-x-8">
      {/* Roue de notation */}
      <div className="flex flex-col items-center">
        <div className="relative w-16 h-16 flex items-center justify-center">
          <svg className="w-full h-full" viewBox="0 0 36 36">
            {/* Cercle de fond */}
            <circle
              cx="18"
              cy="18"
              r="15.9155"
              fill="none"
              stroke="#e6e6e6"
              strokeWidth="2"
            />
            {/* Arc représentant la note */}
            <circle
              cx="18"
              cy="18"
              r="15.9155"
              fill="none"
              stroke="#22c55e"
              strokeWidth="2"
              strokeDasharray={`${strokeValue} 100`}
              strokeDashoffset="0"
              strokeLinecap="round" // Ajoute des bords arrondis
              transform="rotate(-90 18 18)" // Rotation pour démarrer à 12h
            />
          </svg>
          <span className="absolute text-lg font-semibold text-gray-700">
            {averageRating.toFixed(1)}/5
          </span>
        </div>
        <div className="text-sm text-gray-500">{reviews.length} avis</div>
      </div>

      {/* Barre de distribution */}
      <div className="flex flex-col space-y-1">
        {[5, 4, 3, 2, 1].map((score) => (
          <div key={score} className="flex items-center">
            <span className="text-xs text-gray-400">{score}</span>
            <div className="ml-2 bg-gray-200 w-40 h-2 rounded-full">
              <div
                className="bg-green-600 h-full rounded-full"
                style={{ width: `${distribution[score] || 0}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RatingSummary;
