import React, { useState } from "react";
import AddDocuments from "../AddDocuments";
import AddEtiquette from "../../AddEtiquette";

const FormGreenFood = () => {
  const [localProductsInvoices, setLocalProductsInvoices] = useState([]);
  const [selectiveSorting, setSelectiveSorting] = useState(false);
  const [ecoCleaningInvoices, setEcoCleaningInvoices] = useState([]);
  const [associations, setAssociations] = useState([]);
  const [organicWasteExamples, setOrganicWasteExamples] = useState([]);
  const [freshProductsInvoices, setFreshProductsInvoices] = useState([]);
  const [waterEnergyInvoices, setWaterEnergyInvoices] = useState([]);
  const [doggyBag, setDoggyBag] = useState(false);
  const [vegetarianMenuFiles, setVegetarianMenuFiles] = useState([]);
  const [renewableEnergyDocs, setRenewableEnergyDocs] = useState([]);

  const handleAddInvoice = (invoices, setter) => (document) => {
    setter([...invoices, document]);
  };

  const handleAddEtiquette = (list, setter) => (item) => {
    setter([...list, item]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      localProductsInvoices,
      selectiveSorting,
      ecoCleaningInvoices,
      associations,
      organicWasteExamples,
      freshProductsInvoices,
      waterEnergyInvoices,
      doggyBag,
      vegetarianMenuFiles,
      renewableEnergyDocs,
    });
    alert("Formulaire soumis avec succès !");
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Critère 1 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">1. Produits locaux / Bio*</legend>
        <p className="text-gray-600 mb-2">
          Utilisation de produits locaux et/ou bio pour la conception des plats
          :
        </p>
        <AddDocuments
          category={"green-food-invoices"}
          title="Ajouter une facture pour les produits bio"
          onAdd={handleAddInvoice(
            localProductsInvoices,
            setLocalProductsInvoices
          )}
        />
      </fieldset>

      {/* Critère 2 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">2. Tri sélectif</legend>
        <p className="text-gray-600 mb-2">
          Mise en place du tri sélectif dans votre établissement :
        </p>
        <label>
          <input
            type="checkbox"
            checked={selectiveSorting}
            onChange={(e) => setSelectiveSorting(e.target.checked)}
            className="mr-2"
          />
          Oui
        </label>
      </fieldset>

      {/* Critère 3 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">3. Produits d'entretien</legend>
        <p className="text-gray-600 mb-2">
          Utilisation de produits d'entretien naturel et/ou écologique (minimum
          50%) :
        </p>
        <AddDocuments
          category={"green-food-invoices"}
          title="Ajouter des factures de produits d'entretien"
          onAdd={handleAddInvoice(ecoCleaningInvoices, setEcoCleaningInvoices)}
        />
      </fieldset>

      {/* Critère 4 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">4. Revalorisation d'invendus</legend>
        <p className="text-gray-600 mb-2">
          Revaloriser ses invendus par le don aux associations ou la revente à
          moindre coût :
        </p>
        <AddEtiquette
          title="Ajouter une association ou un exemple de revente"
          fields={[
            { name: "name", label: "Nom", type: "text", placeholder: "Nom" },
            {
              name: "example",
              label: "Exemple",
              type: "text",
              placeholder: "Exemple de revente ou action",
            },
          ]}
          onAdd={handleAddEtiquette(associations, setAssociations)}
        />
      </fieldset>

      {/* Critère 5 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">
          5. Revalorisation des déchets organiques
        </legend>
        <p className="text-gray-600 mb-2">
          Compostage, lombricompostage, etc. :
        </p>
        <AddEtiquette
          title="Ajouter un exemple mis en place"
          fields={[
            {
              name: "example",
              label: "Exemple",
              type: "text",
              placeholder: "Exemple",
            },
          ]}
          onAdd={handleAddEtiquette(
            organicWasteExamples,
            setOrganicWasteExamples
          )}
        />
      </fieldset>

      {/* Critère 6 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">6. Flux tendus</legend>
        <p className="text-gray-600 mb-2">
          Utilisation de produits frais par une gestion en flux tendus :
        </p>
        <AddDocuments
          category={"green-food-invoices"}
          title="Ajouter des factures d'achat de produits frais"
          onAdd={handleAddInvoice(
            freshProductsInvoices,
            setFreshProductsInvoices
          )}
        />
      </fieldset>

      {/* Critère 7 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">
          7. Réduction des dépenses d'eau et d'énergies
        </legend>
        <p className="text-gray-600 mb-2">
          Fournir des factures pour prouver les actions de réduction :
        </p>
        <AddDocuments
          title="Ajouter des factures d'eau ou d'énergie"
          onAdd={handleAddInvoice(waterEnergyInvoices, setWaterEnergyInvoices)}
        />
      </fieldset>

      {/* Critère 8 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">8. Doggy bag</legend>
        <p className="text-gray-600 mb-2">
          Proposer un « doggy bag » pour le reste des repas :
        </p>
        <label>
          <input
            type="checkbox"
            checked={doggyBag}
            onChange={(e) => setDoggyBag(e.target.checked)}
            className="mr-2"
          />
          Oui
        </label>
      </fieldset>

      {/* Critère 9 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">9. Menu végétarien</legend>
        <p className="text-gray-600 mb-2">
          Fournir au moins un menu végétarien :
        </p>
        <AddDocuments
          title="Ajouter le fichier du menu végétarien"
          onAdd={handleAddInvoice(vegetarianMenuFiles, setVegetarianMenuFiles)}
        />
      </fieldset>

      {/* Critère 10 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">10. Énergies renouvelables</legend>
        <p className="text-gray-600 mb-2">
          S’approvisionner en énergie renouvelable (factures ou documents) :
        </p>
        <AddDocuments
          category={"green-food-invoices"}
          title="Ajouter une facture ou document prouvant l'utilisation d'énergie renouvelable"
          onAdd={handleAddInvoice(renewableEnergyDocs, setRenewableEnergyDocs)}
        />
      </fieldset>

      <button
        type="submit"
        className="bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700"
      >
        Soumettre
      </button>
    </form>
  );
};

export default FormGreenFood;
