import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../Auth/Provider/AuthProvider";
import EstablishmentHeader from "./EstablishmentHeader";
import HeroEstablisment from "./HeroEstablisment";
import useEstablishment from "../../../hooks/establishment/useEstablishment";
import ReviewList from "./ReviewList";
import MenuEstablishment from "./MenuEstablishment";
import Tabs from "./Tabs";
import ReviewModal from "./ReviewModal";

const Establishment = () => {
  const { establishmentId } = useParams();
  const { user, token } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    getStringAddress,
    showOpeningHoursModal,
    openMapLink,
    openWebsite,
    openPhoneLink,
    getOpeningHours,
    reviews,
    visibleReviews,
    handleFilterChange,
    handleShowMore,
    handleLike,
    handleReport,
    filteredReviews,
    menu,
    handleReviewSubmit,
    establishment,
  } = useEstablishment({
    establishmentId,
    user,
    token,
  });


  if (!establishment) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  const contentTab = {
    Avis: (
      <ReviewList
        establishmentId={establishmentId}
        establishment={establishment}
        reviews={reviews}
        filteredReviews={filteredReviews}
        visibleReviews={visibleReviews}
        handleShowMore={handleShowMore}
        handleLike={handleLike}
        handleReport={handleReport}
        handleFilterChange={handleFilterChange}
        user={user}
        handleAddReview={() => setIsModalOpen(true)} 
      />
    ),

    Menu: <MenuEstablishment menu={menu} />,
  };

  console.log(establishment);

  return (
    <div className="flex flex-col items-center">
      <div className="container mx-auto p-5">
        <EstablishmentHeader
          name={establishment.name}
          rating={establishment.rating}
        />
        <HeroEstablisment
          establishment={establishment}
          getStringAddress={getStringAddress}
          openMapLink={openMapLink}
          openWebsite={openWebsite}
          openPhoneLink={openPhoneLink}
          getOpeningHours={getOpeningHours}
          showOpeningHoursModal={showOpeningHoursModal}
        />

        <Tabs tabs={contentTab} />
      </div>

      <ReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        establishmentId={establishmentId}
        onReviewSubmit={handleReviewSubmit}
      />
    </div>
  );
};

export default Establishment;
