import React from "react";
import Button from "../../elements/Button/Button";
import { useNavigate } from "react-router-dom";

const CardEstablishment = ({ establishment }) => {
  const navigate = useNavigate();
  const handleNavigateToDashBoard = () => {
    navigate(`/dashboard/${establishment._id}`);
  };
  return (
    <div className="flex justify-center flex-col gap-5 items-center w-[500px] p-5 hover:cursor-pointer bg-white shadow-lg rounded-20">
      <h1 className="text-poppins-small">{establishment.name}</h1>
      <Button onClick={handleNavigateToDashBoard}>Gèrer</Button>
    </div>
  );
};

export default CardEstablishment;
