import React from "react";
import ImageLogo from "../../../assets/footerLogo.png";
import RegisterFormPro from "./RegisterFormPro";

const RegisterPro = () => {
  return (
    <div className="w-full h-screen flex bg-tertiary-200">
      <div className="  bg-gray-400 mt-[17px] ml-[17px] mb-[17px] w-[684px] rounded-20"></div>

      <div className=" flex-1 flex items-center justify-center">
        <RegisterFormPro />
      </div>
    </div>
  );
};

export default RegisterPro;
