import { useCallback, useEffect, useState } from "react";
import {
  downloadFile,
  getFilesByEstablishmentId,
  viewFile,
} from "../../services/files.services";

const useFiles = ({ establishmentId }) => {
  console.log(establishmentId);
  const [files, setFiles] = useState([]);

  const fetchFiles = useCallback(async () => {
    try {
      const res = await getFilesByEstablishmentId(establishmentId);
      console.log(res);
      setFiles(res.data.files || []);
    } catch (error) {
      console.error("Failed to fetch files", error);
    }
  }, []);

  const handleDownloadFile = async (fileId) => {
    try {
      await downloadFile(fileId);
    } catch (error) {
      console.error("Failed to download file", error);
    }
  };

  const handleViewFile = async (fileId) => {
    try {
      await viewFile(fileId);
    } catch (error) {
      console.error("Failed to view file", error);
    }
  };

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles]);

  return {
    files,
    handleDownloadFile,
    handleViewFile,
  };
};

export default useFiles;
