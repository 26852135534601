import React from "react";
import { Link } from "react-router-dom";

const SocialLink = ({ logo, link, alt, text }) => {
  return (
    <Link
      to={link}
      className="flex flex-row gap-4"
      target="_blank"
      rel="noreferrer"
    >
      <div>
        <img src={logo} alt={alt} />
      </div>
      <p>{text}</p>
    </Link>
  );
};

export default SocialLink;
