import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../Auth/Provider/AuthProvider";

const OwnerRoute = ({ redirectTo = "/" }) => {
  const { user } = useAuth();

  if (user && user.role !== "owner") {
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
};

export default OwnerRoute;
