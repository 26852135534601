import React, { useState } from "react";

const AddDocuments = ({ onAdd, title, category = "" }) => {
  const [formData, setFormData] = useState({
    title: "",
    category: category || "",
  });
  const [file, setFile] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    if (formData.title.trim() && formData.category.trim() && file) {
      onAdd({ ...formData, file });
      setFormData({ title: "", category: category || "" }); // Reset fields
      setFile(null); // Reset file
    } else {
      alert("Veuillez remplir tous les champs et ajouter un document.");
    }
  };

  return (
    <div className="space-y-4 border p-4 rounded mb-4">
      <h3 className="font-bold">{title}</h3>

      {/* Champ pour le titre */}
      <div>
        <label className="block font-medium mb-2">Titre du document</label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          placeholder="Entrez le titre"
          className="border p-2 w-full rounded"
        />
      </div>

      {/* Champ pour le téléversement de fichier */}
      <div>
        <label className="block font-medium mb-2">Téléverser un document</label>
        <input
          type="file"
          onChange={handleFileChange}
          className="border p-2 w-full rounded"
        />
      </div>

      <button
        onClick={handleAdd}
        className="bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700"
      >
        Ajouter
      </button>
    </div>
  );
};

export default AddDocuments;
