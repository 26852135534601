import React from "react";
import CardAction from "./CardActions/CardAction";
import {
  faCheckCircle,
  faEye,
  faPaperPlane,
  faPen,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

const ActionsEstablishment = () => {
  const { establishmentId } = useParams();

  const commonProps = {
    additionalClassname: "w-[348px] h-[181px]",
    establishmentId,
  };

  return (
    <div className="container mx-auto flex flex-col items-center">
      <h1 className="text-roca-h4-5 mb-6">Mes restaurants</h1>
      <div className="flex flex-row gap-8 justify-center">
        <div>
          <CardAction
            additionalClassname="h-full w-[394px]"
            label="Demander un label"
            icon={faTag}
            navToLink="request-label"
            establishmentId={establishmentId}
            disabled={true}
          />
        </div>

        <div className="grid grid-cols-2 gap-5 h-[394px]">
          <CardAction
            label="Modifier les informations de mon restaurant"
            icon={faPen}
            navToLink="edit-my-establishement"
            {...commonProps}
          />
          <CardAction
            label="Consulter les documents de mon restaurant"
            icon={faEye}
            navToLink="show-files"
            {...commonProps}
          />
          <CardAction
            label="Suivi de mes demandes"
            icon={faPaperPlane}
            navToLink="follow-my-requests"
            disabled={true}
            {...commonProps}
          />
          <CardAction
            label="Voir les avis sur mon restaurant"
            icon={faCheckCircle}
            navToLink="show-reviews-establishements"
            {...commonProps}
          />
        </div>
      </div>
    </div>
  );
};

export default ActionsEstablishment;
