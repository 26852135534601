import React, { useState } from "react";
import MultiStepForm from "../../MultiForm/MultiStepForm";

import { register } from "../../../services/auth.services";

import ProStepOne from "./steps/proSteps/ProStepOne";
import ProStepTwo from "./steps/proSteps/ProStepTwo";
import ProStepThree from "./steps/proSteps/ProStepThree";

const RegisterFormPro = () => {
  const role = "owner";
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    company_name: "",
    company_siret: "",
    company_address: "",
    accepted_cgu: false,
    accepted_notification: false,
    role: role,
    isAdmin: false,
    isVerified: false,
  });

  const handleInputChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const steps = [
    ProStepOne, // Étape 1
    ProStepTwo, // Étape 2
    ProStepThree, // Étape 3
  ];

  const buttons = [
    {
      next: "Suivant",
      linkText: "En savoir plus",
      linkUrl: "/login",
    }, // Étape 1
    { prev: "Précédent", next: "Suivant" }, // Étape 2
    { prev: "Précédent", submit: "Envoyer" }, // Étape 3
  ];

  const handleSubmit = async (data) => {
    const res = await register(data);
  };

  return (
    <MultiStepForm
      steps={steps}
      buttons={buttons}
      onSubmit={handleSubmit}
      formData={formData}
      setFormData={setFormData}
      handleInputChange={handleInputChange}
      skipStep={1}
      returnLink="/register"
    />
  );
};

export default RegisterFormPro;
