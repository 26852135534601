import React from "react";
import Input from "../../../../elements/Input/Input";

const ProStepTwo = ({ formData, handleInputChange }) => {
  return (
    <div className="w-full mt-8 pl-[65px] pr-[65px]">
      <Input
        label="Nom de l'entreprise"
        type="text"
        placeholder="Nom de l'entreprise"
        value={formData.company_name}
        onChange={handleInputChange("company_name")}
      ></Input>
      <Input
        label="Siret"
        type="text"
        placeholder="Siret"
        value={formData.company_siret}
        onChange={handleInputChange("company_siret")}
      ></Input>
      <Input
        label="Adresse"
        type="text"
        placeholder="Adresse"
        value={formData.company_address}
        onChange={handleInputChange("company_address")}
      ></Input>
    </div>
  );
};

export default ProStepTwo;
