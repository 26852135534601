import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useEstablishment from "../../../../hooks/establishment/useEstablishment";
import { updateEstablishmentById } from "../../../../services/establishements.services";
import WhatIsTheAction from "../WhatIsTheAction";
import Input from "../../../elements/Input/Input";
import Button from "../../../elements/Button/Button";
import MapPinMyRestaurant from "./MapPinMyRestaurant";
import { Services, TypeOfCooking } from "../../../../utils/predefinedTerms";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import PhotoEditor from "./PhotoEditor";

const EditMyEstablishment = () => {
  const { establishmentId } = useParams();
  const { establishment } = useEstablishment({ establishmentId });
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    coordinates: {},
    website: "",
    phoneNumber: "",
    openingTime: "",
    closingTime: "",
    infos: [],
    schedule: {
      openingTime: "",
      closingTime: "",
      daysOpen: [],
    },
    photos: [],
    price: "",
  });

  const [newPhotos, setNewPhotos] = useState([]);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [pinCoordinates, setPinCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [showMap, setShowMap] = useState(false);

  // Restriction : Nombre maximal de photos
  const MAX_PHOTOS = 10;

  // Restriction : Taille maximale d'une photo (5 Mo)
  const MAX_FILE_SIZE = 5 * 1024 * 1024;

  useEffect(() => {
    if (establishment) {
      setFormData({
        name: establishment.name || "",
        coordinates: {
          lat: establishment.coordinates[0],
          lng: establishment.coordinates[1],
        },
        description: establishment.description || "",
        street: establishment.address?.street || "",
        city: establishment.address?.city || "",
        postalCode: establishment.address?.postalCode || "",
        country: establishment.address?.country || "",
        website: establishment.website || "",
        phoneNumber: establishment.phoneNumber || "",
        infos: establishment?.infos || [],
        photos: establishment.photos || [],
        price: establishment.price || "",
        schedule: {
          openingTime: establishment.schedule?.openingTime || "",
          closingTime: establishment.schedule?.closingTime || "",
          daysOpen: establishment.schedule?.daysOpen || [],
        },
      });
    }
  }, [establishment]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const toggleDay = (day) => {
    setFormData((prev) => ({
      ...prev,
      schedule: {
        ...prev.schedule,
        daysOpen: prev.schedule.daysOpen.includes(day)
          ? prev.schedule.daysOpen.filter((d) => d !== day)
          : [...prev.schedule.daysOpen, day],
      },
    }));
  };

  const handleInfosClick = (info) => {
    setFormData((prev) => ({
      ...prev,
      infos: prev.infos.includes(info)
        ? prev.infos.filter((i) => i !== info)
        : [...prev.infos, info],
    }));
  };
  const days = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files);

    // Fichiers invalides à retirer
    const invalidFiles = [];

    // Filtrer les fichiers valides
    const validFiles = files.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        Swal.fire({
          icon: "error",
          title: "Fichier trop volumineux",
          text: `Le fichier ${file.name} dépasse la taille maximale de 2 Mo.`,
          confirmButtonText: "OK",
        });
        invalidFiles.push(file.name); // Ajouter le fichier invalide à la liste
        return false; // Exclure le fichier
      }
      return true; // Garder le fichier valide
    });

    // Supprimer les fichiers invalides de formData.photos
    if (invalidFiles.length > 0) {
      setFormData((prev) => ({
        ...prev,
        photos: prev.photos.filter(
          (photo) => !invalidFiles.includes(photo.fileName)
        ),
      }));
    }

    // Vérifier si le nombre total de photos dépasse la limite
    const totalPhotosCount = formData.photos.length + validFiles.length;
    if (totalPhotosCount > MAX_PHOTOS) {
      Swal.fire({
        icon: "warning",
        title: "Trop de photos",
        text: `Vous ne pouvez pas ajouter plus de ${MAX_PHOTOS} photos.`,
        confirmButtonText: "OK",
      });
      return; // Empêcher tout ajout si la limite est dépassée
    }

    // Ajouter uniquement les fichiers valides
    setNewPhotos((prev) => [...prev, ...validFiles]);
    setFormData((prev) => ({
      ...prev,
      photos: [
        ...prev.photos,
        ...validFiles.map((file) => ({
          fileUrl: URL.createObjectURL(file),
          fileName: file.name,
        })),
      ],
    }));
  };

  const deletePhoto = (index, isNew = false) => {
    if (isNew) {
      setNewPhotos((prev) => prev.filter((_, i) => i !== index));
      setFormData((prev) => ({
        ...prev,
        photos: prev.photos.filter(
          (_, i) => i !== formData.photos.length - newPhotos.length + index
        ),
      }));
    } else {
      const photoToDelete = formData.photos[index];
      setDeletedPhotos((prev) => [...prev, photoToDelete]);
      setFormData((prev) => ({
        ...prev,
        photos: prev.photos.filter((_, i) => i !== index),
      }));
    }
  };

  useEffect(() => {
    if (pinCoordinates) {
      setFormData((prev) => ({
        ...prev,
        coordinates: {
          lat: pinCoordinates.lat,
          lng: pinCoordinates.lng,
        },
      }));
    }
  }, [pinCoordinates]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedData = {
        ...formData,
        photos: newPhotos,
        deletedPhotos,
      };
      const res = await updateEstablishmentById(establishmentId, updatedData);
      if (!res) return;
      Swal.fire({
        icon: "success",
        title: "Établissement mis à jour",

        confirmButtonText: "OK",
      }).then(() => {
        navigate(`/dashboard/${establishmentId}`);
      });
    } catch (error) {
      console.error("Erreur lors de la mise à jour :", error);
    }
  };

  return (
    <>
      <WhatIsTheAction actionIs="editMyEstablishement" name={formData.name} />
      <div className="container mx-auto bg-white flex flex-col justify-center p-8 rounded-lg shadow-md">
        <div className="mx-auto w-full flex justify-center mb-8  ">
          <h1 className="text-roca-h5 !text-secondary-600">
            Modifier mon établissement
          </h1>
        </div>
        <div className="flex flex-col gap-y-8">
          {/* Bloc 1 */}
          <div className="flex flex-row gap-x-8">
            <div className="flex-1 p-6 ">
              <Input
                label="Nom du restaurant"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Ex : John Company"
              />
              <Input
                label="Description"
                textArea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                placeholder="Ex : Plats végétariens, desserts maison..."
              />
              <PhotoEditor
                formData={formData}
                handlePhotoChange={handlePhotoChange}
                deletePhoto={deletePhoto}
                newPhotos={newPhotos}
              ></PhotoEditor>
            </div>
            {/* Bloc 2 */}
            <div className="flex-1 p-6 ">
              <Input
                label="Site web"
                type="text"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                placeholder="Ex : https://restaurant.fr"
              />
              <Input
                label="Numéro de téléphone"
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Ex : +33 07 54 63 64 54"
              />
              <div className="flex flex-row gap-4 mt-6">
                <Input
                  label="Heures d’ouverture"
                  type="time"
                  name="openingTime"
                  value={formData.schedule.openingTime}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        openingTime: e.target.value,
                      },
                    }))
                  }
                />
                <Input
                  label="Heures de fermeture"
                  type="time"
                  name="closingTime"
                  value={formData.schedule.closingTime}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      schedule: {
                        ...prev.schedule,
                        closingTime: e.target.value,
                      },
                    }))
                  }
                />
              </div>
              <div className="mt-6">
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Jours ouvrables
                </label>
                <div className="flex flex-wrap gap-2">
                  {days.map((day) => (
                    <button
                      key={day}
                      type="button"
                      className={`px-4 py-2 rounded-lg ${
                        formData.schedule.daysOpen?.includes(day)
                          ? "bg-secondary-600 text-white"
                          : "border border-primary-600 text-primary-900"
                      }`}
                      onClick={() => toggleDay(day)}
                    >
                      {day}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-x-8">
            <div className="flex-1 p-6 ">
              <div>
                <h2 className="text-poppins-small mt-5">Type de Cuisine</h2>
                <select
                  name="typeOfCooking"
                  id="typeOfCooking"
                  value={formData.typeOfCooking}
                  onChange={(e) => handleInputChange(e)}
                  className="w-full bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
                >
                  <option value="" disabled>
                    Sélectionnez un type de cuisine
                  </option>
                  {TypeOfCooking.map((option) => (
                    <option key={option.label} value={option.label}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <h2 className="text-poppins-small mt-5">
                  Quelle est l'addition moyenne par client ?
                </h2>
                <Input
                  type="number"
                  min="0"
                  name="price"
                  placeholder="Prix moyen"
                  value={formData.price}
                  onChange={handleInputChange}
                ></Input>
              </div>
              <div>
                <h2 className="text-poppins-small mt-5">
                  Informations supplémentaires
                </h2>
                <div className="flex gap-2 flex-wrap">
                  {Services.map((service) => (
                    <Button
                      key={service.label}
                      colorType={
                        formData.infos?.includes(service.label)
                          ? "secondary"
                          : "white_border"
                      }
                      onClick={() => handleInfosClick(service.label)}
                      className="flex items-center gap-2"
                    >
                      <span>{service.name}</span>
                    </Button>
                  ))}
                </div>
              </div>
            </div>
            <div className="flex-1 p-6">
              <div>
                <h2 className="text-poppins-small mt-5">Adresse</h2>
                <Input
                  label="Rue"
                  type="text"
                  name="street"
                  value={formData.street}
                  onChange={handleInputChange}
                  placeholder="Ex : 12 rue de la Paix"
                />
                <Input
                  label="Ville"
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="Ex : Paris"
                />
                <Input
                  label="Code postal"
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  placeholder="Ex : 75000"
                />
                <Input
                  label="Pays"
                  type="text"
                  name="country"
                  value={formData.country}
                  onChange={handleInputChange}
                  placeholder="Ex : France"
                />
              </div>
              <div className="w-full mb-8 flex justify-center">
                <Button onClick={() => setShowMap(!showMap)}>
                  {showMap ? "Cacher la carte" : "Montrer la carte"}
                </Button>
              </div>
              {showMap && (
                <div>
                  <MapPinMyRestaurant
                    setPinCoordinates={setPinCoordinates}
                    pinCoordinates={pinCoordinates}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-8 sticky bottom-8 right-0">
          <Button colorType={"secondary"} onClick={handleSubmit}>
            Enregistrer les modifications
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditMyEstablishment;
