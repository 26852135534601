import React, { useState } from "react";

const AddEtiquette = ({ fields, onAdd, title }) => {
  const [formData, setFormData] = useState(() =>
    fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
  );
  const [file, setFile] = useState(null); // État pour gérer le fichier
  const [enableFileUpload, setEnableFileUpload] = useState(false); // État pour activer/désactiver l'upload de fichier

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleAdd = (e) => {
    e.preventDefault();
    const isValid = fields.every((field) => formData[field.name].trim() !== "");
    if (isValid) {
      onAdd({ ...formData, file }); // Inclure le fichier dans les données
      setFormData(
        fields.reduce((acc, field) => ({ ...acc, [field.name]: "" }), {})
      ); // Reset fields
      setFile(null); // Reset file
      setEnableFileUpload(false); // Reset file upload state
    }
  };

  return (
    <div className="space-y-4 border p-4 rounded mb-4">
      <h3 className="font-bold">{title}</h3>
      {fields.map((field) => (
        <div key={field.name}>
          <label className="block font-medium mb-2">{field.label}</label>
          <input
            type={field.type}
            name={field.name}
            value={formData[field.name]}
            onChange={handleChange}
            placeholder={field.placeholder}
            className="border p-2 w-full rounded"
          />
        </div>
      ))}

      {/* Activer ou désactiver le téléversement de fichier */}
      <div>
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={enableFileUpload}
            onChange={() => setEnableFileUpload((prev) => !prev)}
          />
          <span>Ajouter un fichier</span>
        </label>
      </div>

      {/* Champ de téléversement de fichier */}
      {enableFileUpload && (
        <div>
          <label className="block font-medium mb-2">
            Téléverser un fichier
          </label>
          <input
            type="file"
            onChange={handleFileChange}
            className="border p-2 w-full rounded"
          />
        </div>
      )}

      <button
        onClick={handleAdd}
        className="bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700"
      >
        Ajouter
      </button>
    </div>
  );
};

export default AddEtiquette;
