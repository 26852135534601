import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faFlag,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import RatingSummary from "./RatingSummary";
import RatingFilter from "./RatingFilter";
import Button from "../../elements/Button/Button";
import defaultPicture from "../../../assets/avatar.webp";
import { getRatingInStars } from "../../../utils/Establishment";
import { useAuth } from "../../Auth/Provider/AuthProvider";

const ReviewList = ({
  establishmentId,
  reviews,
  visibleReviews,
  handleShowMore,
  handleLike,
  handleReport,
  handleFilterChange,
  filteredReviews,
  user,
  handleAddReview,
  establishment,
}) => {
  const { token } = useAuth();

  const MAX_CHARACTERS = 150; // Nombre maximal de caractères avant de tronquer

  const [expandedReviews, setExpandedReviews] = useState({});
  const [expandedAnswers, setExpandedAnswers] = useState({});

  // Gestion de l'état pour afficher plus/moins pour les reviews
  const toggleReviewExpand = (reviewId) => {
    setExpandedReviews((prev) => ({
      ...prev,
      [reviewId]: !prev[reviewId],
    }));
  };

  // Gestion de l'état pour afficher plus/moins pour les réponses
  const toggleAnswerExpand = (answerId) => {
    setExpandedAnswers((prev) => ({
      ...prev,
      [answerId]: !prev[answerId],
    }));
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-6 mb-6">
      <h2 className="text-xl font-bold mb-3">Avis</h2>

      {reviews?.length === 0 ? (
        <p className="text-center text-gray-500">Aucun avis</p>
      ) : (
        <>
          <div className="flex justify-center gap-5 items-center">
            <RatingSummary reviews={reviews} />
            <RatingFilter onFilterChange={handleFilterChange} />
          </div>

          {filteredReviews.slice(0, visibleReviews).map((review, index) => (
            <div key={index} className="p-4 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <div className="flex gap-5 w-full ">
                  <img
                    src={defaultPicture}
                    alt="profil"
                    className="h-9 w-9 rounded-full"
                  />
                  <div className="w-full">
                    <h3 className="font-semibold text-gray-800">
                      {review.user?.firstname} {review.user?.lastname}
                    </h3>
                    <p className="text-sm italic text-gray-500">
                      {new Date(review.createdAt).toLocaleDateString()}
                    </p>

                    <p className="mt-2 text-gray-600">
                      {review?.comment?.length > MAX_CHARACTERS &&
                      !expandedReviews[review._id]
                        ? `${review.comment.slice(0, MAX_CHARACTERS)}...`
                        : review.comment}
                    </p>
                    {review?.comment?.length > MAX_CHARACTERS && (
                      <button
                        onClick={() => toggleReviewExpand(review._id)}
                        className="text-primary-600 text-sm mt-1"
                      >
                        {expandedReviews[review._id]
                          ? "Afficher moins"
                          : "Afficher plus"}
                      </button>
                    )}

                    {token && (
                      <div className="flex gap-3 mt-2">
                        <button
                          onClick={() => handleLike(review._id)}
                          className={
                            review?.likedBy?.includes(user?.id)
                              ? "text-secondary-500"
                              : "text-gray-500"
                          }
                        >
                          <FontAwesomeIcon icon={faThumbsUp} />{" "}
                          {review.likes_nomber}
                        </button>
                        <button
                          onClick={() => handleReport(review._id)}
                          className={
                            review?.reportedBy?.includes(user?.id)
                              ? "text-red-500"
                              : "text-gray-500"
                          }
                        >
                          <FontAwesomeIcon icon={faFlag} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex top-0 h-full  ">
                  {getRatingInStars(review.rating)}
                </div>
              </div>
              <div>
                {review.answer && review?.answer?.length > 0 && (
                  <div className="mt-4 ml-14 p-4 border-l-2 border-gray-200 bg-gray-50">
                    <div className="flex items-start gap-4">
                      <div className="h-9 w-9 rounded-full bg-tertiary-500 flex justify-center items-center">
                        <FontAwesomeIcon icon={faUtensils} />
                      </div>
                      <div>
                        <h3 className="font-semibold text-primary-700">
                          {establishment.name}{" "}
                          <span className="text-gray-500">• Propriétaire</span>
                        </h3>
                        {review.answer.slice(0, 1).map((ans, ansIndex) => (
                          <div key={ansIndex} className="mt-2">
                            {/* Réponse avec "Afficher plus" */}
                            <p className="text-gray-700">
                              {ans?.comment?.length > MAX_CHARACTERS &&
                              !expandedAnswers[ansIndex]
                                ? `${ans.comment.slice(0, MAX_CHARACTERS)}...`
                                : ans.comment}
                            </p>
                            {ans?.comment?.length > MAX_CHARACTERS && (
                              <button
                                onClick={() => toggleAnswerExpand(ansIndex)}
                                className="text-primary-600 text-sm mt-1"
                              >
                                {expandedAnswers[ansIndex]
                                  ? "Afficher moins"
                                  : "Afficher plus"}
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}

          {filteredReviews?.length > visibleReviews && (
            <div className="flex justify-center mt-4">
              <Button onClick={handleShowMore}>Voir plus</Button>
            </div>
          )}
        </>
      )}

      {token ? (
        <div className="w-full mt-4 sticky bottom-7 flex justify-center">
          <Button onClick={handleAddReview}>Ajouter un avis</Button>
        </div>
      ) : (
        <div className="w-full mt-4 sticky bottom-7 flex justify-center">
          <Button link="/login" goTo={`/establishment/${establishmentId}`}>
            Connectez-vous pour ajouter un avis
          </Button>
        </div>
      )}
    </div>
  );
};

export default ReviewList;
