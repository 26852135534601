import React from "react";
import LogoFooter from "../../assets/footerLogo.png";
import twitter from "../../assets/twitter.svg";
import youtube from "../../assets/youtube.svg";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import SocialLink from "./SocialLink";
import logoFooterMiddle from "../../assets/logoFooterDeux.svg";
import AppleDownload from "../../assets/AppleDownload.svg";
import GoogleDownload from "../../assets/GoogleDownload.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="w-full bg-primary-600 flex flex-col lg:flex-row justify-between p-6 lg:pl-20 lg:pr-20 relative lg:h-[528px] mt-24">
      {/* Section gauche - Logo et liens sociaux */}
      <div className="flex flex-col gap-8 lg:gap-16 mt-8 lg:mt-12 items-center lg:items-start">
        <div>
          <img src={LogoFooter} alt="Logo LabelAdresse" className="w-[120px]" />
        </div>
        <div className="flex flex-col gap-5 text-white items-center lg:items-start">
          <SocialLink
            logo={twitter}
            alt="Logo X"
            link="https://x.com"
            text="Twitter"
          />
          <SocialLink
            logo={youtube}
            alt="Logo YouTube"
            link="https://youtube.com"
            text="YouTube"
          />
          <SocialLink
            logo={instagram}
            alt="Logo Instagram"
            link="https://instagram.com"
            text="Instagram"
          />
          <SocialLink
            logo={facebook}
            alt="Logo Facebook"
            link="https://facebook.com"
            text="Facebook"
          />
        </div>
      </div>

      <div className="flex items-center flex-col gap-8 w-full mt-8 lg:mt-0 relative">
        <div className="text-white w-full flex justify-center lg:justify-end">
          <ul className="flex flex-col lg:flex-row gap-6 mt-8 lg:gap-24 justify-center lg:justify-end items-start lg:items-start">
            {/* <li>
              <Link to="#about">À propos</Link>
            </li> */}
            <li>
              <Link to="#services">Services</Link>
            </li>
            <li>
              <Link to="#contact">Contacts</Link>
            </li>
            <li>
              <Link to="#legal">Mentions légales</Link>
            </li>
            <li className="mt-8 lg:mt-0 ">
              <p>Télécharger l'application</p>
              <div className="flex flex-col mt-4 items-center lg:items-start">
                <a
                  href="https://app-store.com"
                  target="_blank"
                  className="mb-4"
                  rel="noreferrer"
                >
                  <img
                    src={AppleDownload}
                    alt="Download on the App Store"
                    className="w-[150px] lg:w-[180px]"
                  />
                </a>
                <a
                  href="https://app-store.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={GoogleDownload}
                    alt="Get it on Google Play"
                    className="w-[150px] lg:w-[180px]"
                  />
                </a>
              </div>
            </li>
          </ul>
        </div>

        {/* Logo central */}
        <img
          src={logoFooterMiddle}
          alt="Logo central"
          className="hidden lg:block absolute bottom-0 -mb-5"
          //   className="h-[318px] w-[254px] absolute bottom-0 left-1/2 transform -translate-x-1/2 lg:left-auto lg:translate-x-0 lg:right-0"
        />
      </div>
    </div>
  );
};

export default Footer;
