import React, { useState, useEffect, useRef } from "react";
import CardEstablishment from "./CardEstablishment";
import MapResults from "./MapResults";
import FilterDropdown from "../HomePage/Search/FilterDropdown";
// import SearchBar from "../elements/Input/SearchBar";
import { useLocation } from "react-router-dom";
import { searchEstablishments } from "../../services/establishements.services";

const Restaurants = () => {
  const location = useLocation();
  const currentCity = JSON.parse(localStorage.getItem("currentCity"));
  const [establishments, setEstablishments] = useState([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState(null);
  const establishmentRefs = useRef([]);

  const handleFilteredEstablishments = (filteredResults) => {
    setEstablishments(filteredResults);
  };

  // Fonction pour récupérer les établissements
  const fetchEstablishments = async (
    searchQuery,
    lat,
    lon,
    distance = 10000
  ) => {
    try {
      const results = await searchEstablishments(
        searchQuery,
        lat,
        lon,
        distance,
        {}
      );
      setEstablishments(results);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des établissements :",
        error
      );
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("search");

    const fetchData = async () => {
      if (location.state?.establishments) {
        setEstablishments(location.state.establishments);
      } else if (searchQuery) {
        await fetchEstablishments(
          searchQuery,
          currentCity.lat,
          currentCity.lon
        );
      }
    };

    fetchData();
  }, [location.state, location.search, currentCity.lat, currentCity.lon]);

  // Gestion de la recherche dans la zone
  const handleSearchInArea = (lat, lon, bounds) => {
    fetchEstablishments("", lat, lon, bounds);
  };

  // Gestion de la sélection d'un établissement
  useEffect(() => {
    if (selectedEstablishment) {
      const index = establishments.findIndex(
        (establishment) => establishment.name === selectedEstablishment.name
      );
      if (index !== -1 && establishmentRefs.current[index]) {
        establishmentRefs.current[index].scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [selectedEstablishment, establishments]);

  const handleSelectEstablishment = (establishment) => {
    setSelectedEstablishment(establishment);
  };

  return (
    <div className="container mx-auto w-full mt-5 flex-col justify-center flex mb-96">
      <div className="sticky top-0 z-50 bg-white rounded-8">
        {/* <SearchBar /> */}
        <FilterDropdown
          currentCity={currentCity}
          onResultsUpdate={handleFilteredEstablishments}
        />
      </div>
      <div>
        <h2 className="text-roca-h4-5 m-8 w-full flex justify-center">
          {establishments.length} restaurants trouvés
        </h2>
      </div>
      <div className="flex flex-row justify-center">
        <div>
          <div className="grid grid-cols-1 gap-4">
            {establishments.length > 0 ? (
              establishments.map((establishment, index) => (
                <div
                  key={index}
                  ref={(el) => (establishmentRefs.current[index] = el)}
                  className={`cursor-pointer ${
                    selectedEstablishment?.name === establishment.name
                      ? "bg-gray-100 border-l-4 border-secondary-500"
                      : ""
                  }`}
                  onClick={() => handleSelectEstablishment(establishment)}
                >
                  <CardEstablishment
                    establishmentId={establishment._id}
                    name={establishment.name}
                    labels={establishment.labels}
                    images={establishment.photos} // Les images sont déjà incluses
                    description={establishment.description}
                    price={establishment.price}
                    address={establishment.address}
                    rating={establishment.rating}
                  />
                </div>
              ))
            ) : (
              <CardEstablishment isBlank />
            )}
          </div>
        </div>

        <div className="">
          <MapResults
            establishments={establishments}
            selectedEstablishment={selectedEstablishment}
            onSelectEstablishment={handleSelectEstablishment}
            onSearchInArea={handleSearchInArea} // Passer la fonction de recherche
          />
        </div>
      </div>
    </div>
  );
};

export default Restaurants;
