import React, { useState } from "react";
import Accordion from "../../../../../elements/Accordion";
import FormLaClefVertEnvSoc from "./FormLaClefVertEnvSoc";
import FormLaClefVertSensibilisationClient from "./FormLaClefVertSensibilisationClient";
import { Form } from "react-router-dom";
import FormLaCleVertGestionEnergie from "./FormLaCleVertGestionEnergie";
import FormLaCleVertGestionEau from "./FormLaCleVertGestionEau";
import FormLaCleVertGestionDechets from "./FormLaCleVertGestionDechets";
import FormLaClefVertAchatEtOffreAlimentaire from "./FormLaClefVertAchatEtOffreAlimentaire";
import FormLaClefVertAchatsResponsable from "./FormLaClefVertAchatsResponsable";
import FormLaClefVertCadreDeVie from "./FormLaClefVertCadreDeVie";
import FormLaClefVertBonnePratique from "./FormLaClefVertBonnePratique";

const FormLaClefVerteWithAccordion = () => {
  const [activeCategory, setActiveCategory] = useState(null);

  const toggleCategory = (category) => {
    setActiveCategory((prev) => (prev === category ? null : category));
  };

  return (
    <div>
      {/* Introduction */}
      <div className="bg-gray-100 p-4 rounded mb-8">
        <h2 className="text-2xl font-bold mb-2">Formulaire La Clef Verte</h2>
        <p className="text-gray-600">
          Ce formulaire contient des critères <strong>impératifs</strong>, que
          vous devez respecter, et des critères
          <strong> conseillés</strong>, qui témoignent de votre engagement.
          Merci de renseigner tous les champs pertinents.
        </p>
      </div>

      {/* Accordions */}
      <Accordion
        title="Politique environnementale et sociale"
        isOpen={activeCategory === "policy"}
        onToggle={() => toggleCategory("policy")}
      >
        <FormLaClefVertEnvSoc />
      </Accordion>

      <Accordion
        title="Sensibilisation de la clientèle à l'environnement"
        isOpen={activeCategory === "clientAwareness"}
        onToggle={() => toggleCategory("clientAwareness")}
      >
        <FormLaClefVertSensibilisationClient></FormLaClefVertSensibilisationClient>
        <div>
          <p>Questions liées à la sensibilisation de la clientèle.</p>
        </div>
      </Accordion>

      <Accordion
        title="Gestion de l'énergie"
        isOpen={activeCategory === "energyManagement"}
        onToggle={() => toggleCategory("energyManagement")}
      >
        <FormLaCleVertGestionEnergie />
        <div>
          <p>Questions liées à la gestion de l'énergie.</p>
        </div>
      </Accordion>

      <Accordion
        title="Gestion de l'eau"
        isOpen={activeCategory === "waterManagement"}
        onToggle={() => toggleCategory("waterManagement")}
      >
        <FormLaCleVertGestionEau></FormLaCleVertGestionEau>
        <div>
          <p>Questions liées à la gestion de l'eau.</p>
        </div>
      </Accordion>

      <Accordion
        title="Gestion des déchets"
        isOpen={activeCategory === "wasteManagement"}
        onToggle={() => toggleCategory("wasteManagement")}
      >
        <FormLaCleVertGestionDechets></FormLaCleVertGestionDechets>
        <div>
          <p>Questions liées à la gestion des déchets.</p>
        </div>
      </Accordion>

      <Accordion
        title="Achats et offre alimentaire"
        isOpen={activeCategory === "foodPurchasing"}
        onToggle={() => toggleCategory("foodPurchasing")}
      >
        {/* Ajouter le composant correspondant */}
        <FormLaClefVertAchatEtOffreAlimentaire></FormLaClefVertAchatEtOffreAlimentaire>
        <div>
          <p>Questions liées aux achats et à l'offre alimentaire.</p>
        </div>
      </Accordion>

      <Accordion
        title="Achats responsables"
        isOpen={activeCategory === "responsiblePurchasing"}
        onToggle={() => toggleCategory("responsiblePurchasing")}
      >
        {/* Ajouter le composant correspondant */}
        <FormLaClefVertAchatsResponsable></FormLaClefVertAchatsResponsable>
        <div>
          <p>Questions liées aux achats responsables.</p>
        </div>
      </Accordion>

      <Accordion
        title="Cadre de vie"
        isOpen={activeCategory === "livingEnvironment"}
        onToggle={() => toggleCategory("livingEnvironment")}
      >
        {/* Ajouter le composant correspondant */}
        <FormLaClefVertCadreDeVie></FormLaClefVertCadreDeVie>
        <div>
          <p>Questions liées au cadre de vie.</p>
        </div>
      </Accordion>

      <Accordion
        title="Bonnes pratiques"
        isOpen={activeCategory === "bestPractices"}
        onToggle={() => toggleCategory("bestPractices")}
      >
        <FormLaClefVertBonnePratique></FormLaClefVertBonnePratique>
        <div>
          <p>Questions liées aux bonnes pratiques.</p>
        </div>
      </Accordion>
    </div>
  );
};

export default FormLaClefVerteWithAccordion;
