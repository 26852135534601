import React from "react";

const Accordion = ({ title, isOpen, onToggle, children }) => {
  return (
    <div className="border mb-4 rounded">
      {/* Titre de la catégorie */}
      <div
        className="bg-gray-200 px-4 py-2 cursor-pointer flex justify-between items-center"
        onClick={onToggle}
      >
        <h2 className="font-bold">{title}</h2>
        <span>{isOpen ? "▲" : "▼"}</span>
      </div>

      {/* Contenu de la catégorie (visible si ouvert) */}
      {isOpen && <div className="p-4 bg-white">{children}</div>}
    </div>
  );
};

export default Accordion;
