import React, { useState } from "react";
import AddEtiquette from "../../AddEtiquette";

const FormGreenFood = () => {
  // États pour chaque critère
  const [localProducts, setLocalProducts] = useState(false);
  const [selectiveSorting, setSelectiveSorting] = useState(false);
  const [ecoCleaning, setEcoCleaning] = useState(false);
  const [wasteReuse, setWasteReuse] = useState(false);
  const [organicWaste, setOrganicWaste] = useState(false);
  const [freshStock, setFreshStock] = useState(false);
  const [energySavings, setEnergySavings] = useState(false);
  const [doggyBag, setDoggyBag] = useState(false);
  const [vegetarianMenu, setVegetarianMenu] = useState(false);
  const [renewableEnergy, setRenewableEnergy] = useState(false);

  // Listes pour les exemples ajoutés
  const [associations, setAssociations] = useState([]);
  const [cleaningProducts, setCleaningProducts] = useState([]);
  const [compostingMethods, setCompostingMethods] = useState([]);
  const [stockManagementExamples, setStockManagementExamples] = useState([]);
  const [energyReductionActions, setEnergyReductionActions] = useState([]);

  // Handlers pour ajouter des exemples
  const handleAddAssociation = (item) =>
    setAssociations((prev) => [...prev, item]);
  const handleAddCleaningProduct = (item) =>
    setCleaningProducts((prev) => [...prev, item]);
  const handleAddCompostingMethod = (item) =>
    setCompostingMethods((prev) => [...prev, item]);
  const handleAddStockManagementExample = (item) =>
    setStockManagementExamples((prev) => [...prev, item]);
  const handleAddEnergyReductionAction = (item) =>
    setEnergyReductionActions((prev) => [...prev, item]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({
      localProducts,
      selectiveSorting,
      ecoCleaning,
      wasteReuse,
      organicWaste,
      freshStock,
      energySavings,
      doggyBag,
      vegetarianMenu,
      renewableEnergy,
      associations,
      cleaningProducts,
      compostingMethods,
      stockManagementExamples,
      energyReductionActions,
    });
    alert("Formulaire soumis avec succès !");
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Critère 1 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">1. Produits locaux / Bio*</legend>
        <p className="text-gray-600 mb-2">
          Utilisation de produits locaux et/ou bio pour la conception des plats
          :
        </p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={localProducts}
            onChange={(e) => setLocalProducts(e.target.checked)}
            className="mr-2"
          />
          Oui, nous utilisons des produits locaux/bio.
        </label>
      </fieldset>

      {/* Critère 2 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">2. Tri sélectif</legend>
        <p className="text-gray-600 mb-2">Mise en place du tri sélectif :</p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={selectiveSorting}
            onChange={(e) => setSelectiveSorting(e.target.checked)}
            className="mr-2"
          />
          Oui, le tri sélectif est en place.
        </label>
      </fieldset>

      {/* Critère 3 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">3. Produits d'entretien</legend>
        <p className="text-gray-600 mb-2">
          Utilisation de produits d'entretien naturel et/ou écologique (minimum
          50%) :
        </p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={ecoCleaning}
            onChange={(e) => setEcoCleaning(e.target.checked)}
            className="mr-2"
          />
          Oui, nous utilisons des produits d'entretien écologique.
        </label>
        {ecoCleaning && (
          <AddEtiquette
            title="Ajouter un produit d'entretien"
            fields={[
              {
                name: "name",
                label: "Nom du produit",
                type: "text",
                placeholder: "Nom du produit",
              },
              {
                name: "brand",
                label: "Marque",
                type: "text",
                placeholder: "Marque",
              },
            ]}
            onAdd={handleAddCleaningProduct}
          />
        )}
      </fieldset>

      {/* Critère 4 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">4. Revalorisation d'invendus</legend>
        <p className="text-gray-600 mb-2">
          Revaloriser ses invendus par le don aux associations ou la revente à
          moindre coût :
        </p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={wasteReuse}
            onChange={(e) => setWasteReuse(e.target.checked)}
            className="mr-2"
          />
          Oui, les invendus sont revalorisés.
        </label>
        {wasteReuse && (
          <AddEtiquette
            title="Ajouter une association ou acteur local"
            fields={[
              {
                name: "name",
                label: "Nom",
                type: "text",
                placeholder: "Nom de l'association ou acteur",
              },
              {
                name: "contact",
                label: "Contact (Email)",
                type: "email",
                placeholder: "Email de contact",
              },
            ]}
            onAdd={handleAddAssociation}
          />
        )}
      </fieldset>

      {/* Critère 5 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">
          5. Revalorisation des déchets organiques
        </legend>
        <p className="text-gray-600 mb-2">
          Compostage, lombricompostage, etc. :
        </p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={organicWaste}
            onChange={(e) => setOrganicWaste(e.target.checked)}
            className="mr-2"
          />
          Oui, les déchets organiques sont revalorisés.
        </label>
        {organicWaste && (
          <AddEtiquette
            title="Ajouter une méthode de compostage"
            fields={[
              {
                name: "method",
                label: "Méthode",
                type: "text",
                placeholder: "Méthode de compostage",
              },
            ]}
            onAdd={handleAddCompostingMethod}
          />
        )}
      </fieldset>

      {/* Critère 6 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">6. Flux tendus</legend>
        <p className="text-gray-600 mb-2">
          Utilisation de produits frais par une gestion des stocks en flux
          tendus :
        </p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={freshStock}
            onChange={(e) => setFreshStock(e.target.checked)}
            className="mr-2"
          />
          Oui, nous utilisons une gestion des stocks en flux tendus.
        </label>
        {freshStock && (
          <AddEtiquette
            title="Ajouter un exemple de gestion"
            fields={[
              {
                name: "description",
                label: "Description",
                type: "text",
                placeholder: "Description de la méthode",
              },
            ]}
            onAdd={handleAddStockManagementExample}
          />
        )}
      </fieldset>

      {/* Critère 7 */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">7. Dépenses énergétiques</legend>
        <p className="text-gray-600 mb-2">
          Réduction des dépenses d'eau et d'énergies :
        </p>
        <label className="block mb-2">
          <input
            type="checkbox"
            checked={energySavings}
            onChange={(e) => setEnergySavings(e.target.checked)}
            className="mr-2"
          />
          Oui, nous avons mis en place des actions de réduction.
        </label>
        {energySavings && (
          <AddEtiquette
            title="Ajouter une action de réduction"
            fields={[
              {
                name: "name",
                label: "Nom de l'action",
                type: "text",
                placeholder: "Nom de l'action",
              },
              {
                name: "description",
                label: "Description",
                type: "text",
                placeholder: "Description de l'action",
              },
            ]}
            onAdd={handleAddEnergyReductionAction}
          />
        )}
      </fieldset>

      {/* Critères restants */}
      <fieldset className="border p-4 rounded">
        <legend className="font-bold">8. Doggy bag</legend>
        <label>
          <input
            type="checkbox"
            checked={doggyBag}
            onChange={(e) => setDoggyBag(e.target.checked)}
            className="mr-2"
          />
          Oui, des doggy bags sont proposés.
        </label>
      </fieldset>

      <fieldset className="border p-4 rounded">
        <legend className="font-bold">9. Menu végétarien</legend>
        <label>
          <input
            type="checkbox"
            checked={vegetarianMenu}
            onChange={(e) => setVegetarianMenu(e.target.checked)}
            className="mr-2"
          />
          Oui, un menu végétarien est proposé.
        </label>
      </fieldset>

      <fieldset className="border p-4 rounded">
        <legend className="font-bold">10. Énergies</legend>
        <label>
          <input
            type="checkbox"
            checked={renewableEnergy}
            onChange={(e) => setRenewableEnergy(e.target.checked)}
            className="mr-2"
          />
          Oui, nous utilisons de l'énergie renouvelable.
        </label>
      </fieldset>

      <button
        type="submit"
        className="bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700"
      >
        Soumettre
      </button>
    </form>
  );
};

export default FormGreenFood;
