import React from "react";
import mapFrance from "../../assets/mapFrance.svg";

const SectionMap = () => {
  return (
    <div className="bg-primary-600 w-full h-screen flex justify-center items-center ">
      <div className="container mx-auto flex justify-evenly lg:justify-evenly xl:justify-evenly items-center flex-col lg:flex-row xl:flex-row  h-[426px]">
        <div className=" w-[612px] h-[426px]">
          <div>
            <p className="text-poppins-small !text-white">
              Un coup d'œil sur les statistiques
            </p>
          </div>
          <div>
            <h1 className="text-roca-h4-5 !text-white mt-4">
              Nous croyons à l'avenir des <br /> restaurants verts en France !
            </h1>
          </div>
          <div className="mt-10">
            <img src={mapFrance} alt="Illustration_carte_de_france" />
          </div>
        </div>
        <div className="flex flex-col justify-center items-center  h-[426px]">
          <div className="relative bg-other-_bleu rounded-20 flex flex-col justify-center items-center w-[217px] h-[217px] right-40 top-10 z-10 shadow-2xl">
            <div>
              <h1 className="text-roca-h5 !text-white">77%</h1>
            </div>
            <div className="pl-10 pr-10 pb-10 pt-2">
              <p className="text-poppins-extra-small text-center">
                des Français aimeraient trouver des produits alimentaires locaux
                et bio en restaurants.
              </p>
            </div>
          </div>
          <div className="relative bg-white rounded-20 flex flex-col justify-center items-center w-[217px] h-[217px] mt-4 bottom-4 shadow-2xl">
            <div>
              <h1 className="text-roca-h5 !text-primary-600">37%</h1>
            </div>
            <div className="pl-10 pr-10 pb-10 pt-2">
              <p className="text-poppins-extra-small text-center !text-primary-600">
                Cependant, seulement 37 % des restaurants en proposent.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMap;
