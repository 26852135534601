import React, { useState } from "react";
import Button from "./Button/Button";
import Input from "./Input/Input";
import SearchBar from "./Input/SearchBar";
import NavBar from "../NavBar/NavBar";
import Footer from "../Footer/Footer";

const TestUi = () => {
  const [role, setRole] = useState("admin");
  const cities = ["Paris", "Lyon", "Marseille", "Bordeaux"];

  const handleSearch = (city, searchTerm) => {
    console.log(`Searching for "${searchTerm}" in ${city}`);
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };
  return (
    <div className="flex justify-center items-center h-screen flex-col">
      <div className="flex h-screen justify-center items-center flex-col">
        <Button type="primary" AddtionnalClasses={"w-[133px] h-[42px]"}>
          Connexion
        </Button>
      </div>
      <div className="flex h-screen justify-center items-center flex-col">
        <Input placeholder="Text"></Input>
      </div>

      <div className="flex h-screen justify-center items-center flex-col">
        <SearchBar cities={cities} onSearch={handleSearch} />
      </div>

      <div className="flex w-full h-screen justify-center items-center flex-col gap-24 mb-14">
        <select className="w-40 h-10" onChange={handleChangeRole}>
          <option value="admin">admin</option>
          <option value="owner">owner</option>
          <option value="user">user</option>
          <option value="auditor">auditor</option>
          <option value="">Non connecter</option>
        </select>
        <NavBar roleUser={role} />
      </div>

      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default TestUi;
