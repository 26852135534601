import React, { useState, useEffect } from "react";

const AnswerModal = ({ isOpen, onClose, onSubmit, existingComment }) => {
  const [comment, setComment] = useState("");

  // Synchronise `comment` avec `existingComment` quand la modale s'ouvre
  useEffect(() => {
    if (isOpen) {
      setComment(existingComment || ""); // Initialise le commentaire avec `existingComment`
    }
  }, [isOpen, existingComment]);

  const handleSubmit = () => {
    if (comment.trim()) {
      onSubmit(comment);
      setComment("");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg p-6 w-96 shadow-lg">
        <h2 className="text-lg font-bold mb-4">Répondre à l'avis</h2>
        <textarea
          className="w-full border border-gray-300 rounded-lg p-2 mb-4"
          rows="5"
          placeholder="Écrivez votre réponse ici..."
          value={comment} // Utilise `comment` comme valeur
          onChange={(e) => setComment(e.target.value)} // Met à jour `comment` en temps réel
        />
        <div className="flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-800 rounded-lg hover:bg-gray-400"
          >
            Annuler
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
          >
            Envoyer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnswerModal;
