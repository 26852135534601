import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoHand from "../../assets/Logohands.svg";

const CardSection = ({ icon, title, text }) => {
  return (
    <div className=" rounded-20 shadow-lg p-4 bg-white w-[334px] h-[422px]">
      <div className="flex justify-center items-center w-full h-36">
        <img src={logoHand} alt="logo" className="w-full h-full" />
      </div>
      <div className="flex flex-row gap-5 justify-center items-center mt-8">
        <FontAwesomeIcon icon={icon} className="text-primary-600 text-xl" />
        <h1 className="text-poppins-h6 font-semibold text-gray-800">{title}</h1>
      </div>
      <div className="p-4">
        <p className="text-poppins-paragraph text-gray-600">{text}</p>
      </div>
    </div>
  );
};

export default CardSection;
