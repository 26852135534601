import React, { useEffect, useState } from "react";
import Card from "./Card";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/Provider/AuthProvider";
import { getEstablishmentsByOwner } from "../../../services/establishements.services";
import CardEstablishment from "./CardEstablishment";

const MyEstablishement = () => {
  const [establishments, setEstablishments] = useState([]);
  const navigate = useNavigate();

  const handlePassTest = () => {
    console.log("pass test");
  };

  const { user } = useAuth();

  const handleAddEstablishment = () => {
    navigate("/add-establishment");
  };

  useEffect(() => {
    const fetchMyEstablishments = async () => {
      const res = await getEstablishmentsByOwner(user.id);
      if (!res) return;
      const fetchedEstablishments = res.establishments || [];
      setEstablishments(fetchedEstablishments);

      if (fetchedEstablishments.length === 1) {
        navigate(`/dashboard/${fetchedEstablishments[0]._id}`);
      }
    };
    fetchMyEstablishments();
  }, [user.id, navigate]);

  return (
    <div className="container mx-auto flex flex-col justify-center gap-5 items-center">
      <h1 className="text-roca-h4-5">Mes Restaurants</h1>
      <div className="flex flex-col gap-5">
        <div className="flex flex-row gap-5">
          <Card
            onClick={handlePassTest}
            isTest={true}
            additionalClassname={
              establishments?.length > 0
                ? "w-[500px] h-[100px] hover:cursor-pointer"
                : "w-[500px] h-[282px] hover:cursor-pointer"
            }
            subLine={"Cela vous prendra 20 minutes"}
            subLineIcon={faClock}
          />
          <Card
            onClick={handleAddEstablishment}
            isAddEstablishment={true}
            additionalClassname={
              establishments?.length > 0
                ? "w-[500px] h-[100px] hover:cursor-pointer"
                : "w-[500px] h-[282px] hover:cursor-pointer"
            }
            subLine={"Cela vous prendra 10 minutes"}
            subLineIcon={faClock}
          />
        </div>
        <div className="grid grid-cols-2 gap-5">
          {establishments?.length > 0 &&
            establishments.map((establishment) => (
              <CardEstablishment
                key={establishment._id}
                establishment={establishment}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default MyEstablishement;
