import React, { useState } from "react";
import { getCategorie } from "../../../../utils/Categories";

const FilterFiles = ({ files, onFilter }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSizeSort, setSelectedSizeSort] = useState("");
  const [selectedDateSort, setSelectedDateSort] = useState("");

  const categories = [...new Set(files.map((file) => file.category))]; // Récupérer les catégories uniques

  // Fonction pour filtrer par catégorie
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    applyFilters({ category });
  };

  // Fonction pour trier par taille
  const handleSizeSortChange = (e) => {
    const sizeSort = e.target.value;
    setSelectedSizeSort(sizeSort);
    applyFilters({ sizeSort });
  };

  // Fonction pour trier par date
  const handleDateSortChange = (e) => {
    const dateSort = e.target.value;
    setSelectedDateSort(dateSort);
    applyFilters({ dateSort });
  };

  // Fonction pour appliquer les filtres et les tris
  const applyFilters = ({
    category = selectedCategory,
    sizeSort = selectedSizeSort,
    dateSort = selectedDateSort,
  }) => {
    let filteredFiles = [...files];

    // Filtrer par catégorie
    if (category) {
      filteredFiles = filteredFiles.filter(
        (file) => file.category === category
      );
    }

    // Trier par taille
    if (sizeSort === "size-desc") {
      filteredFiles.sort((a, b) => (b.size || 0) - (a.size || 0)); // Du plus lourd au plus léger
    } else if (sizeSort === "size-asc") {
      filteredFiles.sort((a, b) => (a.size || 0) - (b.size || 0)); // Du plus léger au plus lourd
    }

    // Trier par date
    if (dateSort === "date-newest") {
      filteredFiles.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      ); // Du plus récent au plus ancien
    } else if (dateSort === "date-oldest") {
      filteredFiles.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      ); // Du plus ancien au plus récent
    }

    onFilter(filteredFiles);
  };

  return (
    <div className="flex gap-5 mb-4 flex-wrap">
      {/* Filtre par catégorie */}
      <div>
        <label
          htmlFor="categoryFilter"
          className="block text-poppins-extra-small font-medium"
        >
          Catégorie
        </label>
        <select
          id="categoryFilter"
          value={selectedCategory}
          onChange={handleCategoryChange}
          className="mt-1 block w-full py-2 px-3 border border-primary-600 bg-white rounded-20 shadow-sm"
        >
          <option value="">Toutes</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
             {getCategorie(category)?.name || "Autre"}
            </option>
          ))}
        </select>
      </div>

      {/* Tri par taille */}
      <div>
        <label
          htmlFor="sizeSort"
          className="block text-poppins-extra-small font-medium"
        >
          Trier par taille
        </label>
        <select
          id="sizeSort"
          value={selectedSizeSort}
          onChange={handleSizeSortChange}
          className="mt-1 block w-full py-2 px-3 border border-primary-600 bg-white rounded-20 shadow-sm"
        >
          <option value="">Aucun</option>
          <option value="size-desc">Du plus lourd au plus léger</option>
          <option value="size-asc">Du plus léger au plus lourd</option>
        </select>
      </div>

      {/* Tri par date */}
      <div>
        <label
          htmlFor="dateSort"
          className="block text-poppins-extra-small font-medium"
        >
          Trier par date
        </label>
        <select
          id="dateSort"
          value={selectedDateSort}
          onChange={handleDateSortChange}
          className="mt-1 block w-full py-2 px-3 border border-primary-600 bg-white rounded-20 shadow-sm"
        >
          <option value="">Aucun</option>
          <option value="date-newest">Du plus récent au plus ancien</option>
          <option value="date-oldest">Du plus ancien au plus récent</option>
        </select>
      </div>
    </div>
  );
};

export default FilterFiles;
