import React from "react";
import Input from "../../../../elements/Input/Input";

const ProStepOne = ({ formData, handleInputChange }) => {
  return (
    <div className="w-full mt-8 pl-[65px] pr-[65px]">
      <Input
        required
        label="Nom"
        type="text"
        placeholder="Nom"
        value={formData.lastname}
        onChange={handleInputChange("lastname")}
      />
      <Input
        required
        label="Prénom"
        type="text"
        placeholder="Prénom"
        value={formData.firsname}
        onChange={handleInputChange("firstname")}
      />
      <Input
        required
        label="Email"
        type="email"
        placeholder="Email"
        value={formData.email}
        onChange={handleInputChange("email")}
      />

      <Input
        required
        label="Mot de passe"
        type="password"
        placeholder="Mot de passe"
        isShowPassword={true}
        value={formData.password}
        onChange={handleInputChange("password")}
      />
      <Input
        required
        label="Confirmer le mot de passe"
        type="password"
        placeholder="Confirmer le mot de passe"
        isShowPassword={true}
        value={formData.confirmPassword}
        onChange={handleInputChange("confirmPassword")}
      />

      <div className="flex flex-row gap-4 mb-8 mt-8">
        <input type="checkbox"></input>
        <p className="text-poppins-extra-small !text-black">
          J'accepte{" "}
          <a href="/cgu" className="underline">
            les thermes et les conditions d'utilisation
          </a>
        </p>
      </div>

      <div className=" flex flex-row justify-between ">
        <p>Vous avez deja un compte ? </p>
        <a href="/login" className="underline">
          connecter-vous
        </a>
      </div>
    </div>
  );
};

export default ProStepOne;
