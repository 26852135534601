import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../Auth/Provider/AuthProvider";

const AdminRoute = ({ redirectTo = "/" }) => {
  const { user } = useAuth();

  if (user && user.role !== "admin") {
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
};

export default AdminRoute;
