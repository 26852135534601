import React, { useState } from "react";
import MultiStepForm from "../../MultiForm/MultiStepForm";
import PartStepThree from "./steps/partSteps/PartStepThree";
import PartStepTwo from "./steps/partSteps/PartStepTwo";
import PartStepOne from "./steps/partSteps/PartStepOne";
import { register } from "../../../services/auth.services";

const RegisterFormPart = () => {
  const role = "user";
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    birthday: "",
    type_of_diet: "",
    type_of_cuisine: "",
    biographie: "",
    city: "",
    accepted_cgu: false,
    role: role,
    profilePicture: "",
  });

  const handleInputChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const steps = [PartStepOne, PartStepTwo, PartStepThree];

  const handleSubmit = async (data) => {
    const res = await register(data);
  };

  return (
    <MultiStepForm
      steps={steps}
      onSubmit={handleSubmit}
      formData={formData}
      setFormData={setFormData}
      handleInputChange={handleInputChange}
      skipStep={1}
      returnLink="/register"
      validateLink="/success"
    />
  );
};

export default RegisterFormPart;
