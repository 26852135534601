import React from "react";
import Slider from "react-slick";
import VeganLogo from "../../../../../assets/vegan_circle_green.jpg";
import Input from "../../../../elements/Input/Input";

const PartStepTwo = ({ formData, handleInputChange }) => {
  const dietOptions = [
    { value: "vegan", label: "Vegan", imgSrc: VeganLogo },
    {
      value: "vegetarian",
      label: "Végétarien",
      imgSrc: VeganLogo,
    },
    {
      value: "pescatarian",
      label: "Pescetarien",
      imgSrc: VeganLogo,
    },
    { value: "omnivore", label: "Omnivore", imgSrc: VeganLogo },
  ];

  const genderOptions = [
    { value: "male", label: "Homme" },
    { value: "female", label: "Femme" },
    { value: "other", label: "Autre" },
  ];

  const settings = {
    className: "center",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0px",
  };

  return (
    <div className="w-full mt-4 px-16">
      {/* Carrousel pour les préférences alimentaires */}
      <div className="mb-8">
        <h1 className="text-roca-h6 mb-2">Vos préférences</h1>
        <Slider {...settings}>
          {dietOptions.map((option) => (
            <div
              key={option.value}
              style={{ marginRight: "10px" }}
              className={`cursor-pointer p-2 rounded-lg border-2  ${
                formData.type_of_diet === option.value
                  ? "border-orange-500"
                  : "border-gray-300"
              }`}
              onClick={() =>
                handleInputChange("type_of_diet")({
                  target: { value: option.value },
                })
              }
            >
              <img
                src={option.imgSrc}
                alt={option.label}
                className="w-24 h-24 object-cover rounded-md mx-auto"
              />
              <p className="text-center mt-2">{option.label}</p>
            </div>
          ))}
        </Slider>
      </div>

      {/* Boutons radio pour le sexe */}
      <div className="mb-8">
        <h2 className="text-roca-h6 mb-2">Sexe</h2>
        <div className="flex gap-4">
          {genderOptions.map((option) => (
            <label key={option.value} className="flex items-center gap-2">
              <input
                type="radio"
                name="gender"
                value={option.value}
                checked={formData.gender === option.value}
                onChange={handleInputChange("gender")}
                className="form-radio text-orange-500 "
              />
              <p className="text-poppins-small">{option.label}</p>
            </label>
          ))}
        </div>
      </div>

      {/* Champ de saisie pour la date de naissance */}
      <div className="mb-8">
        <h2 className="text-roca-h6 mb-2">Date de naissance</h2>
        <Input
          type="date"
          name="birthday"
          value={formData.birthday}
          onChange={handleInputChange("birthday")}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
        />
      </div>

      {/* Champ d'upload de fichier personnalisé pour la photo de profil */}
      <div className="mb-8">
        <h2 className="text-roca-h6 mb-2">Photo de profil</h2>
        <input
          type="file"
          name="profilePicture"
          onChange={handleInputChange("profilePicture")}
          className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-orange-50 file:text-orange-700 hover:file:bg-orange-100"
        />
      </div>
    </div>
  );
};

export default PartStepTwo;
