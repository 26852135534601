import React from "react";

const TitreForm = ({ currentStep }) => {
  return (
    <>
      {currentStep === 0 && (
        <h1 className="text-roca-h4-5 font-bold !text-secondary-600  text-center mt-[63px]">
          Commençons pas là
        </h1>
      )}
      {currentStep === 1 && (
        <h1 className="text-roca-h4-5 font-bold !text-secondary-600  text-center mt-[63px]">
          Dites nous en plus
        </h1>
      )}

      {currentStep === 2 && (
        <h1 className="text-roca-h4-5 font-bold !text-secondary-600  text-center mt-[63px]">
          Dernier étapes
        </h1>
      )}
    </>
  );
};

export default TitreForm;
