import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TestUi from "./components/elements/TestUi";
import Contact from "./components/Contact/Contact";
import ContactPro from "./components/Contact/ContactPro";
import Login from "./components/Auth/Login/Login";
import Error from "./components/Error/Error";
import Labels from "./components/Labels/Labels";
import About from "./components/About/About";
import AdminRoute from "./components/ProtectRoute/AdminRoute";
import AuditorRoute from "./components/ProtectRoute/AuditorRoute";
import BackOffice from "./components/BackOffice/BackOffice";
import MyRequests from "./components/Auditor/MyRequest/MyRequests";
import AllRequests from "./components/Auditor/AllRequests/AllRequests";
import Cookies from "./components/CGU/Cookies";
import TermsAndConditions from "./components/CGU/TermsAndConditions";
import PrivacyPolicy from "./components/CGU/PrivacyPolicy";
import Review from "./components/Review/Review";
import ShowFiles from "./components/Owner/ActionsEstablishement/ShowFiles/ShowFiles";
import RequestLabel from "./components/Owner/ActionsEstablishement/RequestLabel/RequestLabel";
import EditMyEstablishement from "./components/Owner/ActionsEstablishement/EditMyEstablishement/EditMyEstablishement";
import MyEstablishement from "./components/Owner/MyEstablishement/MyEstablishement";
import OwnerRoute from "./components/ProtectRoute/OwnerRoute";
import ActionsEstablishement from "./components/Owner/ActionsEstablishement/ActionsEstablishement";
import FollowRequest from "./components/Owner/ActionsEstablishement/FollowRequest/FollowRequest";
import ShowReviewsEstablishement from "./components/Owner/ActionsEstablishement/ShowReviewsEstablishement/ShowReviewsEstablishement";
import { AuthProvider } from "./components/Auth/Provider/AuthProvider";
import NavigationWrapper from "./NavigationWrapper";
import FooterWrapper from "./FooterWrapper";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SelectRegister from "./components/Auth/Register/SelectRegister";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RegisterPart from "./components/Auth/Register/RegisterPart";
import RegisterPro from "./components/Auth/Register/RegisterPro";
import Restaurant from "./components/SearchResult/Restaurants";
import Establishment from "./components/SearchResult/Establishment/Establishment";
import HomePages from "./pages/HomePages";
import HomePageContact from "./components/Contact/HomePageContact";
import BackOfficeFaq from "./components/Contact/Faq/BackOfficeFaq";
import FormContact from "./components/Contact/FormContact";
import Profil from "./components/Profile/Profil";
import AddEstablishment from "./components/Owner/ActionsEstablishement/AddEstablishment/AddEstablishment";

function App() {
  return (
    <div className="flex flex-col h-screen ">
      <Router>
        <AuthProvider>
          {/* Wrap the whole application inside NavigationWrapper to handle NavBar visibility */}
          <NavigationWrapper>
            <Routes>
              <Route path="/" element={<HomePages />} />
              <Route path="/test" element={<TestUi />} />
              <Route path="/contacts" element={<HomePageContact />} />
              <Route path="/contact/new" element={<FormContact />} />
              <Route path="/profil" element={<Profil />} />
              <Route path="/about" element={<About />} />
              <Route path="/labels" element={<Labels />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SelectRegister />} />
              <Route path="/register/pro" element={<RegisterPro />} />
              <Route path="/register/part" element={<RegisterPart />} />
              <Route path="/review/:establishementId" element={<Review />} />

              <Route path="/establishments" element={<Restaurant />} />
              <Route
                path="/establishment/:establishmentId"
                element={<Establishment />}
              />

              <Route element={<AdminRoute redirectTo="/" />}>
                <Route path="/backoffice" element={<BackOffice />} />
                <Route path="/backoffice/faq" element={<BackOfficeFaq />} />
              </Route>

              <Route element={<AuditorRoute redirectTo="/" />}>
                <Route path="/all-requests" element={<AllRequests />} />
                <Route path="/my-requests" element={<MyRequests />} />
              </Route>

              <Route element={<OwnerRoute redirectTo="/" />}>
                <Route
                  path="/my-establishements"
                  element={<MyEstablishement />}
                />
                <Route
                  path="/add-establishment"
                  element={<AddEstablishment />}
                />
                <Route
                  path="/dashboard/:establishmentId"
                  element={<ActionsEstablishement />}
                />
                <Route
                  path="/dashboard/:establishmentId/action/request-label"
                  element={<RequestLabel />}
                />
                <Route
                  path="/dashboard/:establishmentId/action/show-files"
                  element={<ShowFiles />}
                />
                <Route
                  path="/dashboard/:establishmentId/action/edit-my-establishement"
                  element={<EditMyEstablishement />}
                />
                <Route
                  path="/dashboard/:establishmentId/action/follow-my-requests"
                  element={<FollowRequest />}
                />
                <Route
                  path="/dashboard/:establishmentId/action/show-reviews-establishements"
                  element={<ShowReviewsEstablishement />}
                />

                <Route path="/contacts-pro" element={<HomePageContact />} />
              </Route>

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
              />
              <Route path="/cookies-policy" element={<Cookies />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </NavigationWrapper>
          <FooterWrapper />
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
