import React from "react";
import { getImageOfTheLabelAndName } from "../../utils/Labels"; // Assurez-vous que le chemin est correct

const CardLabel = ({ label }) => {
  const labelInfo = getImageOfTheLabelAndName(label.slugLabel);

  return (
    <div className="bg-white rounded-20 shadow-md p-6 w-[482px] h-[405px] overflow-auto">
      <div className="flex gap-5">
        {labelInfo && labelInfo.image && (
          <img
            src={labelInfo.image}
            alt={labelInfo.name}
            className="w-16 h-16 rounded-full"
          />
        )}
        <div>
          <h3 className="text-roca-h6">
            {labelInfo ? labelInfo.name : label.name}
          </h3>
        </div>
      </div>
      <div className="h-[1px] bg-black bg-opacity-20 mb-4 mt-4"></div>
      <div>
        <p className="text-poppins-small">{label.description}</p>
      </div>
    </div>
  );
};

export default CardLabel;
