import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Card = ({
  isAddEstablishment,
  isTest,
  label,
  additionalClassname,
  subLine,
  subLineIcon,
  onClick,
}) => {
  const getCardStyles = () => {
    if (isTest) {
      return "bg-quinaire-600 text-poppins-small bg-opacity-10 border border-quinaire-600";
    } else if (isAddEstablishment) {
      return "bg-secondary-600 text-poppins-small bg-opacity-10 border border-secondary-600";
    }
    return "";
  };

  const getLabel = () => {
    if (isTest) {
      return "+ Passer le test";
    } else if (isAddEstablishment) {
      return "+ Ajouter un restaurant";
    }
    return "";
  };

  return (
    <div className="flex flex-col">
      <div
        onClick={onClick}
        className={`flex flex-col items-center justify-center p-6 rounded-lg ${getCardStyles()} w-64 h-36 shadow-sm ${additionalClassname} `}
      >
        <div className="text-lg font-medium">{getLabel()}</div>
      </div>
      <p className=" flex flex-row gap-1 items-center justify-end text-sm text-gray-500 mt-4">
        <FontAwesomeIcon icon={subLineIcon} className="mr-2" />
        {subLine}
      </p>
    </div>  
  );
};

export default Card;
