import React from "react";
import LoginForm from "./LoginForm";
import { useSearchParams } from "react-router-dom";
import loginImage from "../../../assets/loginImage.svg";

const Login = () => {
  const [searchParams] = useSearchParams();
  const goTo = searchParams.get("goTo");

  console.log("goTo", goTo);

  return (
    <div className="w-full h-screen flex bg-tertiary-200">
      <div className="  mt-[17px] ml-[17px] mb-[17px]  rounded-20">
        <img src={loginImage} alt=""  className="h-full w-[684px] "/>
      </div>

      <div className=" flex-1 flex items-center justify-center">
        <LoginForm goTo={goTo} />
      </div>
    </div>
  );
};

export default Login;
