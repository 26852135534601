import React, { useState } from "react";
import Input from "../elements/Input/Input";
import Button from "../elements/Button/Button";

const ContactMail = () => {
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/mqakozbb", {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setStatus("SUCCESS");
        form.reset();
      } else {
        setStatus("ERROR");
      }
    } catch (error) {
      setStatus("ERROR");
    }
  };

  return (
    <div className="flex flex-col items-center bg-[#F4F1E9] py-12 px-6">
      <div className="w-full max-w-6xl mb-12">
        <p className="text-roca-h3 mb-2">Vous voulez en savoir plus ?</p>
        <h1 className="text-poppins-h6 ">
          Saisissez votre adresse électronique pour obtenir plus d'informations
          sur LabelAdresse.
        </h1>
      </div>

      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-1 gap-6 w-full max-w-6xl bg-white rounded-20 p-6"
      >
        <div className="flex items-end w-full h-auto ">
          <Input
            type="email"
            name="email"
            label="Adresse e-mail"
            placeholder="Entrez votre adresse e-mail"
            required
          />
          <Button
            colorType="primary"
            type="submit"
            AddtionnalClasses="mb-4 w-1/4 w-full ml-4"
          >
            Recevoir l'info
          </Button>
        </div>
      </form>

      {status === "SUCCESS" && (
        <p className="text-green-600 mt-4">
          Merci ! Votre message a été envoyé avec succès.
        </p>
      )}
      {status === "ERROR" && (
        <p className="text-red-600 mt-4">
          Une erreur est survenue. Veuillez réessayer plus tard.
        </p>
      )}
    </div>
  );
};

export default ContactMail;
 