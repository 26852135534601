import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getEstablishments = async () => {
  const res = axios.get(`${API_URL}/establishments`);
  if (!res) return;
  return res;
};

export const getEstablishmentById = async (establishmentId) => {
  const res = await axios.get(`${API_URL}/establishments/${establishmentId}`);
  if (!res) return;
  return res?.data;
};

export const createEstablishment = async (establishmentData) => {
  try {
    const formData = new FormData();
    for (const key in establishmentData) {
      if (key === "schedule") {
        formData.append(
          "schedule[openingTime]",
          establishmentData.schedule.openingTime || ""
        );
        formData.append(
          "schedule[closingTime]",
          establishmentData.schedule.closingTime || ""
        );
        establishmentData.schedule.daysOpen.forEach((day) => {
          formData.append("schedule[daysOpen][]", day);
        });
      } else if (key === "photo" && Array.isArray(establishmentData[key])) {
        establishmentData[key].forEach((file) =>
          formData.append("photo", file)
        );
      } else if (Array.isArray(establishmentData[key])) {
        establishmentData[key].forEach((item) =>
          formData.append(`${key}[]`, item)
        );
      } else {
        formData.append(key, establishmentData[key]);
      }
    }

    const res = await axios.post(`${API_URL}/establishments`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'établissement :", error);
    throw error;
  }
};

export const updateEstablishmentById = async (establishmentId, formData) => {
  try {
    const data = new FormData();

    // Ajout des photos
    if (formData.photos && formData.photos.length) {
      formData.photos.forEach((file) => {
        data.append("photo", file);
      });
    }

    // Ajout des coordonnées (lat et lng)
    if (
      formData.coordinates?.lat !== null &&
      formData.coordinates?.lng !== null
    ) {
      data.append("coordinates[lat]", formData.coordinates.lat);
      data.append("coordinates[lng]", formData.coordinates.lng);
    }
    // Ajout des champs de schedule
    if (formData.schedule) {
      data.append("schedule[openingTime]", formData.schedule.openingTime || "");
      data.append("schedule[closingTime]", formData.schedule.closingTime || "");
      if (Array.isArray(formData.schedule.daysOpen)) {
        formData.schedule.daysOpen.forEach((day) => {
          data.append("schedule[daysOpen][]", day);
        });
      }
    }

    // Ajout des photos à supprimer
    if (formData.deletedPhotos && Array.isArray(formData.deletedPhotos)) {
      formData.deletedPhotos.forEach((photo) => {
        if (typeof photo === "object" && photo._id) {
          // Si c'est un objet, ajoutez l'ID
          data.append("deletedPhotos", photo._id);
        } else if (typeof photo === "string") {
          // Si c'est déjà une chaîne, ajoutez-la directement
          data.append("deletedPhotos", photo);
        }
      });
    }

    // Ajout des autres champs
    for (const key in formData) {
      if (!["photos", "deletedPhotos", "schedule"].includes(key)) {
        if (Array.isArray(formData[key])) {
          formData[key].forEach((item) => data.append(key, item));
        } else {
          data.append(key, formData[key]);
        }
      }
    }

    // Requête PATCH avec axios
    const res = await axios.patch(
      `${API_URL}/establishments/${establishmentId}`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de l'établissement :", error);
    throw error;
  }
};

export const deleteEstablishmentById = async (establishmentId) => {
  const res = axios.delete(`${API_URL}/establishments/${establishmentId}`);
  if (!res) return;
  return res;
};

//filter

//NE PAS OUBLIER LA LOCALISATION CAR MIDDLEWARE

export const getEstablishmentsByLabels = async (
  longitude,
  latitude,
  maxDistance,
  labels
) => {
  const res = axios.get(
    `${API_URL}/establishments/filter/${longitude}/${latitude}/${maxDistance}/labels`,
    { labels }
  );

  if (!res) return;
  return res;
};

export const getEstablishementsByInfos = async (
  longitude,
  latitude,
  maxDistance,
  infos
) => {
  const res = axios.get(
    `${API_URL}/establishments/filter/${longitude}/${latitude}/${maxDistance}/infos`,
    { infos }
  );

  if (!res) return;
  return res;
};

export const getEstablishmentsByCategory = async (
  longitude,
  latitude,
  maxDistance,
  categories
) => {
  const res = axios.get(
    `${API_URL}/establishments/filter/${longitude}/${latitude}/${maxDistance}/categories`,
    { categories }
  );

  if (!res) return;
  return res;
};

export const getEstablishementsByPrice = async (
  longitude,
  latitude,
  maxDistance,
  price
) => {
  const res = axios.get(
    `${API_URL}/establishments/filter/${longitude}/${latitude}/${maxDistance}/price`,
    { price }
  );

  if (!res) return;
  return res;
};

export const getEstablishmentsByOpenNow = async (
  longitude,
  latitude,
  maxDistance
) => {
  const res = axios.get(
    `${API_URL}/establishments/filter/${longitude}/${latitude}/${maxDistance}/openNow`
  );
  if (!res) return;
  return res;
};

export const getEstablishmentsByCity = async (city) => {
  const _city = city.toLowerCase();
  const res = await axios.get(`${API_URL}/establishments/search/city/${_city}`);
  if (!res) return;
  return res.data;
};

export const searchEstablishments = async (
  searchTerm,
  lat,
  lon,
  distance,
  filters
) => {
  try {
    const response = await axios.get(
      `${API_URL}/establishments/global/search`,
      {
        params: {
          searchTerm,
          lat,
          lon,
          distance,
          filters,
        },
      }
    );
    return response?.data?.results;
  } catch (error) {
    console.error("Erreur lors de la recherche des établissements :", error);
    throw error;
  }
};

export const getEstablishmentsByOwner = async (ownerId) => {
  const res = await axios.get(`${API_URL}/establishments/owner/${ownerId}`);
  if (!res) return;
  return res.data;
};
