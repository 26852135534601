import {
  faConciergeBell, // Services
  faUtensils, // Type de cuisine
  faGlassCheers, // Boissons
  faMusic, // Divertissement
  faLeaf, // Écologique
  faCertificate,
  faMenorah,
} from "@fortawesome/free-solid-svg-icons";

export const predefinedTerms = [
  // Services
  {
    label: "wifi",
    category: "services",
    categoryLabel: "Services",
    name: "WiFi gratuit",
    icon: faConciergeBell, // Icône pour "Services"
  },
  {
    label: "terrace",
    category: "services",
    categoryLabel: "Services",
    name: "Terrasse",
    icon: faConciergeBell,
  },
  {
    label: "disabled",
    category: "services",
    categoryLabel: "Services",
    name: "Accessible aux fauteuils roulants",
    icon: faConciergeBell,
  },
  {
    label: "pet-friendly",
    category: "services",
    categoryLabel: "Services",
    name: "Animaux acceptés",
    icon: faConciergeBell,
  },
  {
    label: "parking",
    category: "services",
    categoryLabel: "Services",
    name: "Parking",
    icon: faConciergeBell,
  },
  {
    label: "kids-friendly",
    category: "services",
    categoryLabel: "Services",
    name: "Adapté aux enfants",
    icon: faConciergeBell,
  },
  {
    label: "delivery",
    category: "services",
    categoryLabel: "Services",
    name: "Livraison",
    icon: faConciergeBell,
  },
  {
    label: "takeaway",
    category: "services",
    categoryLabel: "Services",
    name: "À emporter",
    icon: faConciergeBell,
  },
  {
    label: "cashless",
    category: "services",
    categoryLabel: "Services",
    name: "Paiement sans contact",
    icon: faConciergeBell,
  },
  {
    label: "outdoor-seating",
    category: "services",
    categoryLabel: "Services",
    name: "Sièges en extérieur",
    icon: faConciergeBell,
  },
  {
    label: "bike-parking",
    category: "services",
    categoryLabel: "Services",
    name: "Parking vélos",
    icon: faConciergeBell,
  },
  {
    label: "reservations",
    category: "services",
    categoryLabel: "Services",
    name: "Réservation possible",
    icon: faConciergeBell,
  },
  {
    label: "group-seating",
    category: "services",
    categoryLabel: "Services",
    name: "Places pour groupes",
    icon: faConciergeBell,
  },
  {
    label: "rooftop",
    category: "services",
    categoryLabel: "Services",
    name: "Toit-terrasse",
    icon: faConciergeBell,
  },
  {
    label: "drive-through",
    category: "services",
    categoryLabel: "Services",
    name: "Service au volant",
    icon: faConciergeBell,
  },
  {
    label: "late-night",
    category: "services",
    categoryLabel: "Services",
    name: "Ouvert tard",
    icon: faConciergeBell,
  },
  {
    label: "smoking-area",
    category: "services",
    categoryLabel: "Services",
    name: "Zone fumeurs",
    icon: faConciergeBell,
  },
  {
    label: "non-smoking",
    category: "services",
    categoryLabel: "Services",
    name: "Non-fumeur",
    icon: faConciergeBell,
  },
  {
    label: "charging-stations",
    category: "services",
    categoryLabel: "Services",
    name: "Stations de recharge",
    icon: faConciergeBell,
  },
  {
    label: "fast-service",
    category: "services",
    categoryLabel: "Services",
    name: "Service rapide",
    icon: faConciergeBell,
  },
  {
    label: "private-dining",
    category: "services",
    categoryLabel: "Services",
    name: "Salle privée",
    icon: faConciergeBell,
  },
  {
    label: "food-truck",
    category: "services",
    categoryLabel: "Services",
    name: "Camion de restauration",
    icon: faConciergeBell,
  },
  {
    label: "communal-seating",
    category: "services",
    categoryLabel: "Services",
    name: "Places communes",
    icon: faConciergeBell,
  },

  // Type de cuisine
  {
    label: "halal",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine halal",
    icon: faUtensils,
  },
  {
    label: "kosher",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine casher",
    icon: faUtensils,
  },
  // Labels de prix et évaluation
  {
    label: "michelin-star",
    category: "labels",
    categoryLabel: "Labels",
    name: "Étoilé Michelin",
    icon: faCertificate,
  },
  // Autres ambiances
  {
    label: "family-friendly",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Adapté aux familles",
    icon: faMenorah,
  },
  {
    label: "allergen-friendly",
    category: "services",
    categoryLabel: "Services",
    name: "Adapté aux personnes allergiques",
    icon: faConciergeBell,
  },
  {
    label: "bakery",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Boulangerie/pâtisserie",
    icon: faUtensils,
  },
  {
    label: "vegan",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Options véganes",
    icon: faUtensils, // Icône pour "Type de cuisine"
  },
  {
    label: "vegetarian",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Options végétariennes",
    icon: faUtensils,
  },
  {
    label: "organic",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Produits bio",
    icon: faUtensils,
  },
  {
    label: "gluten-free",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Sans gluten",
    icon: faUtensils,
  },
  {
    label: "seafood-specialty",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Spécialité fruits de mer",
    icon: faUtensils,
  },
  {
    label: "steakhouse",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Spécialité viandes/grillades",
    icon: faUtensils,
  },
  {
    label: "buffet",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Buffet à volonté",
    icon: faUtensils,
  },
  {
    label: "brunch",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Brunch",
    icon: faUtensils,
  },
  {
    label: "afternoon-tea",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Thé de l'après-midi",
    icon: faUtensils,
  },
  {
    label: "healthy-food",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine santé",
    icon: faUtensils,
  },
  {
    label: "fusion-cuisine",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine fusion",
    icon: faUtensils,
  },
  {
    label: "street-food",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine de rue",
    icon: faUtensils,
  },

  // Boissons
  {
    label: "wine-bar",
    category: "drinks",
    categoryLabel: "Boissons",
    name: "Bar à vins",
    icon: faGlassCheers, // Icône pour "Boissons"
  },
  {
    label: "cocktail-bar",
    category: "drinks",
    categoryLabel: "Boissons",
    name: "Bar à cocktails",
    icon: faGlassCheers,
  },
  {
    label: "beer-garden",
    category: "drinks",
    categoryLabel: "Boissons",
    name: "Biergarten",
    icon: faGlassCheers,
  },
  {
    label: "café",
    category: "drinks",
    categoryLabel: "Boissons",
    name: "Café",
    icon: faGlassCheers,
  },
  {
    label: "brewery",
    category: "drinks",
    categoryLabel: "Boissons",
    name: "Brasserie",
    icon: faGlassCheers,
  },

  // Divertissement
  {
    label: "live-music",
    category: "entertainment",
    categoryLabel: "Divertissement",
    name: "Musique live",
    icon: faMusic, // Icône pour "Divertissement"
  },
  {
    label: "sports-screen",
    category: "entertainment",
    categoryLabel: "Divertissement",
    name: "Diffusion d'événements sportifs",
    icon: faMusic,
  },
  {
    label: "game-room",
    category: "entertainment",
    categoryLabel: "Divertissement",
    name: "Salle de jeux",
    icon: faMusic,
  },
  {
    label: "karaoke",
    category: "entertainment",
    categoryLabel: "Divertissement",
    name: "Karaoké",
    icon: faMusic,
  },
  {
    label: "escape-room",
    category: "entertainment",
    categoryLabel: "Divertissement",
    name: "Escape room",
    icon: faMusic,
  },

  // Ambiance
  {
    label: "romantic",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Atmosphère romantique",
    icon: faMenorah, // Icône pour "Ambiance"
  },
  {
    label: "luxury",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Service de luxe",
    icon: faMenorah,
  },
  {
    label: "quiet-atmosphere",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Atmosphère calme",
    icon: faMenorah,
  },
  {
    label: "waterfront",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Vue sur l'eau",
    icon: faMenorah,
  },
  {
    label: "mountain-view",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Vue sur la montagne",
    icon: faMenorah,
  },

  {
    label: "beach",
    category: "ambiance",
    categoryLabel: "Ambiance",
    name: "Vue sur la plage",
    icon: faMenorah,
  },

  // Écologique
  {
    label: "locally-sourced",
    category: "eco",
    categoryLabel: "Écologique",
    name: "Produits locaux",
    icon: faLeaf, // Icône pour "Écologique"
  },
  {
    label: "eco-friendly",
    category: "eco",
    categoryLabel: "Écologique",
    name: "Respectueux de l'environnement",
    icon: faLeaf,
  },
  {
    label: "sustainable",
    category: "eco",
    categoryLabel: "Écologique",
    name: "Pratiques durables",
    icon: faLeaf,
  },
  {
    label: "zero-waste",
    category: "eco",
    categoryLabel: "Écologique",
    name: "Zéro déchet",
    icon: faLeaf,
  },

  // Labels
  {
    label: "la_clef_verte",
    category: "labels",
    categoryLabel: "Labels",
    name: "Clef Verte",
    icon: faCertificate, // Icône pour "Labels"
  },
  {
    label: "ecotable",
    category: "labels",
    categoryLabel: "Labels",
    name: "Écotable",
    icon: faCertificate,
  },
  {
    label: "green_food",
    category: "labels",
    categoryLabel: "Labels",
    name: "GreenFood",
    icon: faCertificate,
  },
  {
    label: "v_label",
    category: "labels",
    categoryLabel: "Labels",
    name: "V-Label",
    icon: faCertificate,
  },
  {
    label: "framheim",
    category: "labels",
    categoryLabel: "Labels",
    name: "Framheim",
    icon: faCertificate,
  },
  {
    label: "asc_aquaculture",
    category: "labels",
    categoryLabel: "Labels",
    name: "ASC Aquaculture",
    icon: faCertificate,
  },
  {
    label: "ethic_ocean",
    category: "labels",
    categoryLabel: "Labels",
    name: "Ethic Ocean",
    icon: faCertificate,
  },
  {
    label: "ab_bio",
    category: "labels",
    categoryLabel: "Labels",
    name: "AB Bio",
    icon: faCertificate,
  },
];

export const TypeOfCooking = [
  {
    label: "halal",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine halal",
    icon: faUtensils,
  },
  {
    label: "kosher",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine casher",
    icon: faUtensils,
  },
  {
    label: "vegan",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Options véganes",
    icon: faUtensils,
  },
  {
    label: "vegetarian",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Options végétariennes",
    icon: faUtensils,
  },
  {
    label: "organic",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Produits bio",
    icon: faUtensils,
  },
  {
    label: "gluten-free",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Sans gluten",
    icon: faUtensils,
  },
  {
    label: "seafood-specialty",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Spécialité fruits de mer",
    icon: faUtensils,
  },
  {
    label: "steakhouse",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Spécialité viandes/grillades",
    icon: faUtensils,
  },
  {
    label: "buffet",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Buffet à volonté",
    icon: faUtensils,
  },
  {
    label: "brunch",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Brunch",
    icon: faUtensils,
  },
  {
    label: "afternoon-tea",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Thé de l'après-midi",
    icon: faUtensils,
  },
  {
    label: "italian",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine italienne",
    icon: faUtensils,
  },
  {
    label: "french",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine française",
    icon: faUtensils,
  },
  {
    label: "japanese",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine japonaise",
    icon: faUtensils,
  },
  {
    label: "chinese",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine chinoise",
    icon: faUtensils,
  },
  {
    label: "indian",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine indienne",
    icon: faUtensils,
  },
  {
    label: "mexican",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine mexicaine",
    icon: faUtensils,
  },
  {
    label: "thai",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine thaïlandaise",
    icon: faUtensils,
  },
  {
    label: "spanish",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine espagnole",
    icon: faUtensils,
  },
  {
    label: "middle-eastern",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine du Moyen-Orient",
    icon: faUtensils,
  },
  {
    label: "greek",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine grecque",
    icon: faUtensils,
  },
  {
    label: "korean",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine coréenne",
    icon: faUtensils,
  },
  {
    label: "vietnamese",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine vietnamienne",
    icon: faUtensils,
  },
  {
    label: "lebanese",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine libanaise",
    icon: faUtensils,
  },
  {
    label: "turkish",
    category: "type de cuisine",
    categoryLabel: "Type de cuisine",
    name: "Cuisine turque",
    icon: faUtensils,
  },
];

export const Services = [
  {
    label: "wifi",
    category: "services",
    categoryLabel: "Services",
    name: "WiFi gratuit",
    icon: faConciergeBell, // Icône pour "Services"
  },
  {
    label: "terrace",
    category: "services",
    categoryLabel: "Services",
    name: "Terrasse",
    icon: faConciergeBell,
  },
  {
    label: "disabled",
    category: "services",
    categoryLabel: "Services",
    name: "Accessible aux fauteuils roulants",
    icon: faConciergeBell,
  },
  {
    label: "pet-friendly",
    category: "services",
    categoryLabel: "Services",
    name: "Animaux acceptés",
    icon: faConciergeBell,
  },
  {
    label: "parking",
    category: "services",
    categoryLabel: "Services",
    name: "Parking",
    icon: faConciergeBell,
  },
  {
    label: "kids-friendly",
    category: "services",
    categoryLabel: "Services",
    name: "Adapté aux enfants",
    icon: faConciergeBell,
  },
  {
    label: "delivery",
    category: "services",
    categoryLabel: "Services",
    name: "Livraison",
    icon: faConciergeBell,
  },
  {
    label: "takeaway",
    category: "services",
    categoryLabel: "Services",
    name: "À emporter",
    icon: faConciergeBell,
  },
  {
    label: "cashless",
    category: "services",
    categoryLabel: "Services",
    name: "Paiement sans contact",
    icon: faConciergeBell,
  },
  {
    label: "outdoor-seating",
    category: "services",
    categoryLabel: "Services",
    name: "Sièges en extérieur",
    icon: faConciergeBell,
  },
  {
    label: "bike-parking",
    category: "services",
    categoryLabel: "Services",
    name: "Parking vélos",
    icon: faConciergeBell,
  },
  {
    label: "reservations",
    category: "services",
    categoryLabel: "Services",
    name: "Réservation possible",
    icon: faConciergeBell,
  },
];
