import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import Input from "./Input";

const InputKm = ({ value, onChange }) => {
  const [distance, setDistance] = useState(value ? value / 1000 : 10); // Valeur par défaut convertie en km
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null); // Référence pour le menu déroulant

  const handleDistanceChange = (event) => {
    const newDistance = event.target.value;
    setDistance(newDistance);
    onChange(newDistance * 1000); // Convertir en mètres avant de passer la valeur au parent
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Inverser l'état d'ouverture du dropdown
  };

  // Gestion des clics en dehors du composant pour fermer le dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full">
      {/* Bouton pour ouvrir le dropdown */}
      <div
        className="flex items-center justify-between cursor-pointer w-40 bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
        onClick={toggleDropdown}
      >
        <p>Distance (km)</p>
        <FontAwesomeIcon icon={faChevronDown} className="text-gray-600" />
      </div>

      {/* Dropdown ouvert pour choisir la distance */}
      {isDropdownOpen && (
        <div ref={dropdownRef} className="mt-4 absolute bg-white p-5 rounded-8">
          {/* Input pour entrer la distance */}
          <Input
            type="number"
            min="0"
            max="100"
            value={distance}
            onChange={handleDistanceChange}
            className="w-full mt-2 p-2 border rounded"
            placeholder="Entrez une distance"
          />
        </div>
      )}
    </div>
  );
};

export default InputKm;
