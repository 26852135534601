import React, { useRef } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ImageCarrousel = ({ images }) => {
  const galleryRef = useRef(null); // Utilisation de useRef pour accéder à la galerie

  const handleImageClick = () => {
    const currentIndex = galleryRef.current.getCurrentIndex(); // Récupère l'index actuel
    const nextIndex = (currentIndex + 1) % images.length; // Calcule l'index suivant
    galleryRef.current.slideToIndex(nextIndex); // Passe à l'image suivante
  };

  const formattedImages = images.map((image) => ({
    original: image.fileUrl, // URL de l'image principale
    thumbnail: image.fileUrl, // URL de la miniature (peut être la même)
  }));

  return (
    <ImageGallery
      ref={galleryRef} // Référence à la galerie
      items={formattedImages}
      showNav={false} // Désactive les flèches de navigation
      showPlayButton={false} // Désactive le bouton "play"
      showFullscreenButton={false} // Désactive le bouton "fullscreen"
      showThumbnails={true} // Garde les miniatures
      thumbnailPosition="bottom" // Position des miniatures
      onClick={handleImageClick} // Appelle la fonction lors d'un clic
    />
  );
};

export default ImageCarrousel;
