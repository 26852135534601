import { useCallback, useEffect, useState } from "react";
import { getAllLabels } from "../../services/label.services";

const useLabels = () => {
  const [labels, setLabels] = useState([]);

  const fetchLabels = useCallback(async () => {
    try {
      const res = await getAllLabels();
      setLabels(res.labels || []);
    } catch (error) {
      console.error("Failed to fetch labels", error);
    }
  }, []);

  useEffect(() => {
    fetchLabels();
  }, [fetchLabels]);

  return {
    labels,
  };
};

export default useLabels;
