import React from "react";
import SearchComponent from "./Search/SearchComponent";
import WhyLabelAdresse from "./WhyLabelAdresse";
import SectionMap from "./SectionMap";
import Mission from "./Mission";
import Teams from "./Teams";
import DiscoverApp from "./DiscoverApp";
import ContactMail from "./ContactMail";

const HomePage = () => {
  return (
    <>
      <div className="h-screen flex justify-center bg-hero bg-cover bg-center ">
        <SearchComponent />
      </div>
      <section className="container mx-auto h-screen">
        <WhyLabelAdresse />
      </section>
      <section className="w-full h-screen">
        <SectionMap />
      </section>
      <section className="container mx-auto h-screen">
        <Mission />
      </section>
      <section className="container mx-auto h-screen">
        <Teams />
      </section>
      <section className="container mx-auto h-screen">
        <DiscoverApp />
      </section>
      <section className="container mx-auto h-screen">
        <ContactMail />
      </section>
    </>
  );
};

export default HomePage;
