import React from "react";
import SearchBar from "../../elements/Input/SearchBar";

const SearchComponent = () => {
  return (
    <div className="bg-primary-600 rounded-20 w-[844px] h-[463px] mt-[77px] mb-[77px]">
      <div className="pt-[93px] pl-[46px] pr-[46px]">
        <h1 className="text-roca-h4 !text-white">
          Découvre ton propre Label adresse
        </h1>
        <h2 className="text-poppins-paragraph !text-white mt-7">
          Brasserie française, repas asiatique ou fait main... Tout ce que vous
          voulez et bon pour la planète !
        </h2>
        <div className="mt-6">
          <SearchBar />
        </div>
      </div>
    </div>
  );
};

export default SearchComponent;
