import { useCallback, useEffect, useState } from "react";

import {
  deleteReview,
  getReviewsByEstablishmentId,
  removeAnswer,
  updateAnswer,
  updateReview,
} from "../../services/review.services";

const useReviews = ({ establishmentId }) => {
  const [reviews, setReviews] = useState([]);
  const fetchReviews = useCallback(async () => {
    const res = await getReviewsByEstablishmentId(establishmentId);
    console.log(res);
    if (!res) return;
    setReviews(res?.reviews || []);
  }, []);

  const deleteAnswerOfReview = async (reviewId) => {
    const res = await removeAnswer(reviewId);
    if (!res) return;
    fetchReviews();
  };

  const handleUpadateReview = async (reviewId, reviewData) => {
    const res = await updateReview(reviewId, reviewData);
    if (!res) return;
    fetchReviews();
  };

  const handleUpdateAnswer = async (reviewId, answer) => {
    const res = await updateAnswer(reviewId, answer);
    if (!res) return;
    fetchReviews();
  };

  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  return {
    reviews,
    deleteAnswerOfReview,
    handleUpadateReview,
    refreshReviews: fetchReviews,
    handleUpdateAnswer,
  };
};

export default useReviews;
