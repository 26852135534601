import React from "react";
import CardProPart from "./CardProPart";
import { useNavigate } from "react-router-dom";
import registerImage from "../../../assets/registerImage.svg";

const SelectRegister = () => {
  const navigate = useNavigate();

  const goToPro = () => {
    navigate("/register/pro");
  };

  const goToPart = () => {
    navigate("/register/part");
  };
  return (
    <div className="w-full h-screen flex bg-tertiary-200">
      <div className="  mt-[17px] ml-[17px] mb-[17px] w-[684px] rounded-20">
        <img src={registerImage} alt="" className="h-full w-[684px]" />
      </div>

      <div className=" flex-1 flex items-center justify-center">
        <div className="flex gap-5 bg-white rounded-20 flex-col w-[540px] h-[346px] justify-center items-center">
          <div>
            <h1 className="text-roca-h4 !text-secondary-600">Je suis</h1>
          </div>
          <div className="flex gap-5">
            <CardProPart text="Particulier" onClick={goToPart} />

            <CardProPart text="Entreprise" onClick={goToPro} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectRegister;
