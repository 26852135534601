import React from "react";

const Profil = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      en construction
    </div>
  );
};

export default Profil;
