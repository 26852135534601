import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createFile = async (fileData) => {
  const res = await axios.post(`${API_URL}/files`, fileData);

  if (!res) return;
  return res;
};

export const getFileById = async (fileId) => {
  const res = await axios.get(`${API_URL}/files/${fileId}`);
  if (!res) return;
  return res;
};

export const getFiles = async () => {
  const res = await axios.get(`${API_URL}/files`);
  if (!res) return;
  return res;
};

export const updateFileById = async (fileId, fileData) => {
  const res = await axios.patch(`${API_URL}/files/${fileId}`, fileData);
  if (!res) return;
  return res;
};

export const deleteFileById = async (fileId) => {
  const res = await axios.delete(`${API_URL}/files/${fileId}`);
  if (!res) return;
  return res;
};

export const getFilesByEstablishmentId = async (establishmentId) => {
  const res = await axios.get(
    `${API_URL}/files/establishment/${establishmentId}`
  );
  if (!res) return;
  return res;
};

export const getFilesByEstablishmentIdAndCategory = async (
  establishmentId,
  category
) => {
  const res = await axios.get(
    `${API_URL}/files/establishment/${establishmentId}/category/${category}`
  );

  if (!res) return;
  return res?.data.files;
};

export const downloadFile = async (fileId) => {
  try {
    const res = await axios.get(`${API_URL}/files/${fileId}/download`, {
      responseType: "blob", // Permet de gérer le contenu binaire
    });

    // Récupérer le type MIME
    const mimeType = res.headers["content-type"] || "application/octet-stream";

    // Récupérer le nom du fichier depuis les headers (si disponible)
    const contentDisposition = res.headers["content-disposition"];
    const filename = contentDisposition
      ? contentDisposition.split("filename=")[1].replace(/"/g, "")
      : "downloaded_file";

    // Créer un URL Blob pour télécharger le fichier
    const fileURL = window.URL.createObjectURL(
      new Blob([res.data], { type: mimeType })
    );
    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", filename); // Spécifie le nom du fichier
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Failed to download file", error);
  }
};

export const viewFile = async (fileId) => {
  try {
    const res = await axios.get(`${API_URL}/files/${fileId}/view`, {
      responseType: "blob", // Nécessaire pour manipuler les fichiers binaires
    });

    const mimeType = res.headers["content-type"] || "application/octet-stream";

    // Créez un Blob à partir de la réponse
    const fileURL = window.URL.createObjectURL(
      new Blob([res.data], { type: mimeType })
    );

    console.log("Generated file URL:", fileURL); // Ajoutez un log pour déboguer l'URL

    // Ouvrez le fichier dans un nouvel onglet
    window.open(fileURL, "_blank");
  } catch (error) {
    console.error("Failed to view file", error);
  }
};
