import React, { useEffect } from "react";
import HeaderLabel from "./HeaderLabel";
import useLabels from "../../hooks/labels/useLabels";
import CardLabel from "./CardLabel";
import Button from "../elements/Button/Button";

const Labels = () => {
  const { labels } = useLabels();

  return (
    <div className="">
      <HeaderLabel />

      <div className="container mx-auto flex flex-col items-center justify-center">
        <div>
          <h1 className="text-roca-h4 mb-8 text-start">
            Label que nous défendons
          </h1>
          <div className="grid grid-cols-2 gap-2 items-center">
            {labels.length > 0 ? (
              labels.map((label) => <CardLabel key={label._id} label={label} />)
            ) : (
              <p>Aucun label disponible</p>
            )}
          </div>
          <div className="flex justify-center items-center">
            <Button AddtionnalClasses={"mt-8"} colorType="secondary">
              Obtenir un label
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Labels;
