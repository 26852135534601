import React, { useState } from "react";
import TitreForm from "./TitreForm";
import Button from "../elements/Button/Button";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { register, VerifyCodeRegister } from "../../services/auth.services";

const MultiStepForm = ({
  steps,
  onSubmit,
  formData,
  setFormData,
  handleInputChange,
  returnLink,
  validateLink,
  skipStep,
  role,
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [codeReset, setCodeReset] = useState("");

  const navigate = useNavigate();

  const isLastStep = currentStep === steps.length - 1;
  const isSecondToLastStep = currentStep === steps.length - 2;

  const verifPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
      Swal.fire({
        icon: "warning",
        title: "Les mots de passe ne correspondent pas",
        text: "Veuillez saisir le même mot de passe dans les deux champs.",
        confirmButtonText: "OK",
      });
      return false;
    }
    return true;
  };

  const verifyFormatEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      Swal.fire({
        icon: "warning",
        title: "Format d'email invalide",
        text: "Veuillez saisir un email valide.",
        confirmButtonText: "OK",
      });
      return false;
    }
    return true;
  };

  const isStepValid = () => {
    switch (currentStep) {
      case 0:
        return (
          formData.firstname &&
          formData.lastname &&
          formData.email &&
          formData.password &&
          formData.confirmPassword
        );
      case 1:
        if (role === "owner") {
          return (
            formData.company_name &&
            formData.company_siret &&
            formData.company_address
          );
        }
        if (role === "user") {
          return (
            formData.type_of_diet &&
            formData.gender &&
            formData.birthday &&
            formData.profilePicture
          );
        }
        return false;
      default:
        return true;
    }
  };

  const nextStep = () => {
    if (
      (currentStep === 0 &&
        !verifPassword(formData.password, formData.confirmPassword),
      !verifyFormatEmail(formData.email))
    ) {
      return;
    }

    if (!isStepValid()) {
      Swal.fire({
        icon: "warning",
        title: "Champs incomplets",
        text: "Veuillez remplir tous les champs requis avant de continuer.",
        confirmButtonText: "OK",
      });
      return;
    }

    if (isSecondToLastStep) {
      handleFinalSubmit();
    }

    if (!isLastStep) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    } else if (returnLink) {
      navigate(returnLink);
    }
  };

  const handleSkip = () => {
    if (skipStep !== undefined && skipStep < steps.length - 1) {
      setCurrentStep(skipStep + 1);
      if (isSecondToLastStep) {
        handleFinalSubmit();
      }
    }
  };

  const handleFinalSubmit = async () => {
    const res = await register(formData);
  };

  const confirmCode = async () => {
    Swal.fire({
      title: "Code de vérification",
      input: "text",
      inputLabel: "Entrez le code de vérification envoyé à votre email",
      showCancelButton: true,
      confirmButtonText: "Vérifier",
      showLoaderOnConfirm: true,
      preConfirm: async (codeRegister) => {
        try {
          const response = await VerifyCodeRegister(
            formData.email,
            codeRegister
          );
          if (
            response.data.message === "Invalid code" ||
            response.data.message === "Code expired"
          ) {
            throw new Error(response.data.message);
          }
          Swal.fire({
            icon: "success",
            title: "Code vérifié avec succès !",
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            navigate("/login");
          });
        } catch (error) {
          Swal.showValidationMessage(
            "Code invalide ou expiré. Veuillez réessayer."
          );
        }
      },
    });
  };

  // Rendre le composant d'étape actuel
  const StepComponent = steps[currentStep];

  return (
    <div className="flex flex-col items-center">
      <div className="bg-white rounded-20 w-[530px] h-[773px]">
        <TitreForm currentStep={currentStep} />
        <StepComponent
          formData={formData}
          setFormData={setFormData}
          handleInputChange={handleInputChange}
        />

        <div className="w-full flex mt-8 justify-center gap-4 items-end">
          <Button
            colorType="primary"
            AddtionnalClasses="w-[159px]"
            onClick={prevStep}
          >
            Retour
          </Button>

          {!isLastStep ? (
            <Button
              colorType="secondary"
              AddtionnalClasses="w-[159px]"
              onClick={nextStep}
            >
              {isSecondToLastStep
                ? "Valider"
                : currentStep === 1
                ? "Suivant"
                : "Continuer"}
            </Button>
          ) : (
            <Button
              colorType="secondary"
              AddtionnalClasses="w-[159px]"
              onClick={confirmCode}
            >
              Valider
            </Button>
          )}
        </div>
      </div>

      {/* Dots indicateurs */}
      <div className="flex justify-center mt-4 gap-2">
        {steps.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 rounded-full ${
              currentStep === index ? "bg-orange-500" : "bg-gray-400"
            }`}
          />
        ))}
      </div>

      {/* Bouton Skip */}
      {skipStep === currentStep && (
        <div className="mt-2">
          <p
            className="cursor-pointer text-orange-500 text-poppins-paragraph"
            onClick={handleSkip}
          >
            Passer
          </p>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
