

export const getImageOfTheLabelAndName = (label) => {
  switch (label) {
    case "ecotable":
      return {
        name: "Ecotable",
        image: require("../assets/images/labels/ecotable.png"),
      };
    case "la_clef_verte":
      return {
        name: "La Clef Verte",
        image: require("../assets/images/labels/la_clef_verte.png"),
      };
    case "green_food":
      return {
        name: "Green Food",
        image: require("../assets/images/labels/greenfood.png"),
      };
    case "bon_pour_le_climat":
      return {
        name: "Bon Pour Le Climat",
        image: require("../assets/images/labels/bon-pour-le-climat.jpg.webp"),
      };
    case "v_label":
      return {
        name: "V-Label",
        image: require("../assets/images/labels/v_label.png"),
      };
    case "fig":
      return {
        name: "Fig",
        image: require("../assets/images/labels/fig.png"),
      };
    case "framheim":
      return {
        name: "Framheim",
        image: require("../assets/images/labels/framheim.png"),
      };
    case "ab_bio":
      return {
        name: "AB Bio",
        image: require("../assets/images/labels/ab_bio.png"),
      };
    case "ecolabel_eur":
      return {
        name: "Ecolabel Européen",
        image: require("../assets/images/labels/ecolabel_eu.png"),
      };
    case "ethic_ocean":
      return {
        name: "Ethic Ocean",
        image: require("../assets/images/labels/ethic_ocean.png"),
      };
    case "asc_aquaculture":
      return {
        name: "ASC Aquaculture",
        image: require("../assets/images/labels/asc_aquaculture.png"),
      };
    default:
      return;
  }
};

// Import des icônes nécessaires

