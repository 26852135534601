import React, { useState } from "react";
import Button from "../elements/Button/Button";
import { getImageOfTheLabelAndName } from "../../utils/Labels";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const CardEstablishment = ({
  name,
  labels,
  description,
  price,
  address,
  rating,
  establishmentId,
  images,
  isBlank,
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Fonction pour passer à l'image suivante
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  // Fonction pour revenir à l'image précédente
  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // Affichage spécifique si aucun établissement n'est trouvé
  if (isBlank) {
    return (
      <div className="border rounded-lg shadow p-4 flex flex-col items-center justify-center space-y-4 bg-gray-50">
        <h2 className="text-2xl font-bold text-gray-500">
          Aucun établissement trouvé
        </h2>
        <p className="text-gray-400 text-center">
          Nous n'avons pas pu trouver d'établissements correspondant à vos
          critères. Essayez d'élargir votre recherche ou vérifiez vos filtres.
        </p>
        <Button link="/" colorType="primary">
          Retour à l'accueil
        </Button>
      </div>
    );
  }

  return (
    <div className="border rounded-lg shadow p-4 flex space-x-4">
      {/* Carrousel d'images avec taille fixe */}
      <div className="relative w-96 h-64 overflow-hidden">
        {images && images.length > 0 && (
          <>
            <img
              src={images[currentImageIndex]?.fileUrl}
              alt={`${name} - ${currentImageIndex + 1}`}
              className="rounded-lg w-full h-full object-cover"
            />
            {/* Boutons précédent et suivant */}
            {images.length > 1 && (
              <>
                <button
                  onClick={prevImage}
                  className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow hover:bg-gray-100"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
                <button
                  onClick={nextImage}
                  className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-white rounded-full p-2 shadow hover:bg-gray-100"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </>
            )}
          </>
        )}
      </div>

      <div className="w-2/3 pl-4 flex flex-col justify-between">
        {/* Informations du restaurant */}
        <div>
          <h3 className="text-roca-h6  font-bold">{name}</h3>
          <p className="text-gray-600 text-poppins-small">{description}</p>
          <p className="text-sm text-gray-500 text-poppins-small">
            {address?.street}, {address?.city}
          </p>
        </div>

        {/* Prix et note */}
        <div className="flex justify-between items-center mt-2">
          <span className="text-lg font-bold !text-green-500 text-poppins-small">
            {price} €
          </span>
          {rating && (
            <span className="text-poppins-small">
              {rating && Math.round(rating)} / 5
            </span>
          )}
        </div>

        {/* Scroll horizontal des labels */}
        <div className="mt-4 overflow-x-auto whitespace-nowrap">
          <div className="lable-scrool flex gap-5 space-x-4 overflow-auto w-[500px]">
            {labels.map((label, index) => {
              const labelInfo = getImageOfTheLabelAndName(label);
              return labelInfo ? (
                <div
                  key={index}
                  className="flex flex-col items-center text-center space-y-1"
                >
                  <div className="w-10 h-10">
                    <img
                      src={labelInfo.image}
                      alt={labelInfo.name}
                      className="w-full h-full rounded-full"
                    />
                  </div>
                  <span className="text-poppins-extra-small">
                    {labelInfo.name}
                  </span>
                </div>
              ) : (
                <span
                  key={index}
                  className="text-xs bg-gray-200 rounded-full px-2 py-1"
                >
                  {label}
                </span>
              );
            })}
          </div>
        </div>

        {/* Bouton d'action */}
        <div className="mt-4">
          <Button link={`/establishment/${establishmentId}`}>
            En savoir plus
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CardEstablishment;
