import React from "react";
import { Link } from "react-router-dom";

const Button = ({
  children,
  onClick,
  colorType = "primary",
  disabled,
  AddtionnalClasses,
  type,
  link,
  goTo,
}) => {
  const baseClasses = "px-4 py-2 focus:outline-none rounded-20";

  const typeClasses = {
    primary: "bg-primary-600 text-white",
    primary_border: "border border-primary-600 text-primary-600",
    secondary: "bg-secondary-600 text-white",
    secondary_border: "border border-secondary-600 text-secondary-600",
    white: "bg-white text-primary-600",
    white_border: "border border-primary-600 text-primary-600",
  };

  const selectedClasses = typeClasses[colorType] || typeClasses.primary;

  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
    if (onClick) {
      onClick(e);
    }
  };

  if (link) {
    const url = goTo ? `${link}?goTo=${encodeURIComponent(goTo)}` : link;
    return (
      <Link
        to={url}
        onClick={handleClick}
        className={`${baseClasses} ${selectedClasses} ${
          disabled ? "opacity-50 cursor-not-allowed" : ""
        } ${AddtionnalClasses}`}
      >
        {children}
      </Link>
    );
  }

  
  return (
    <button
      type={type}
      onClick={handleClick}
      className={`${baseClasses} ${selectedClasses} ${
        disabled ? "opacity-50 cursor-not-allowed" : ""
      } ${AddtionnalClasses}`}
    >
      {children}
    </button>
  );
};

export default Button;
