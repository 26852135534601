import React from "react";

const CardExplainLabel = ({ title, text, image }) => {
  return (
    <div className="flex flex-col justify-start items-center h-auto w-64 ">
      <div>
        <img src={image} alt="" />
      </div>
      <div>
        <h1 className="text-poppins-h6 mb-2 mt-2">{title}</h1>
      </div>
      <div className="text-center text-poppins-small">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default CardExplainLabel;
