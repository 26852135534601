import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";

const PhotoEditor = ({
  formData,
  newPhotos,
  deletePhoto,
  handlePhotoChange,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null); // Pour gérer la modal de l'image sélectionnée

  const closeModal = () => {
    setSelectedPhoto(null);
  };

  // Gestion de la fermeture via Échap
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <div className="mt-6">
      <label className="block text-poppins-small text-gray-700 mb-2">
        Modifier les photos
      </label>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {formData.photos.map((photo, index) => (
          <div key={index} className="relative">
            <img
              src={photo?.fileUrl || ""}
              alt={`Establishment pic ${index + 1}`}
              className="h-32 w-32 object-cover rounded-md shadow cursor-pointer"
              onClick={() => setSelectedPhoto(photo?.fileUrl || "")} // Afficher l'image en grand
            />
            <button
              type="button"
              className="absolute top-2 right-2 bg-red-500 h-8 w-8 text-white rounded-full p-1"
              onClick={() =>
                deletePhoto(
                  index,
                  index >= formData.photos.length - newPhotos.length
                )
              }
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ))}
        <input
          type="file"
          multiple
          accept="image/*"
          id="photo-upload"
          className="hidden"
          onChange={handlePhotoChange}
        />
        <label
          htmlFor="photo-upload"
          className="h-32 w-32 bg-gray-200 flex items-center justify-center rounded-md shadow border border-dashed cursor-pointer"
        >
          <span className="text-2xl text-secondary-600">
            <FontAwesomeIcon icon={faPlus} />
          </span>
        </label>
      </div>

      {/* Modal pour afficher l'image en grand */}
      {selectedPhoto && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeModal} // Fermer en cliquant à l'extérieur
        >
          <div
            className="relative "
            onClick={(e) => e.stopPropagation()} // Empêche la fermeture lors d'un clic sur l'image
          >
            <img
              src={selectedPhoto}
              alt="Large preview"
              className="w-full h-96 rounded-lg bg-white"
            />
            <button
              type="button"
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-2"
              onClick={closeModal}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoEditor;
