import React, { useState } from "react";
import {
  faEnvelope,
  faLock,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ButtonComponent from "../../elements/Button/Button";
import Input from "../../elements/Input/Input";
import { ReactComponent as FacebookIcon } from "../../../assets/FacebookLogin.svg";
import { ReactComponent as GoogleIcon } from "../../../assets/googleLogin.svg";
import { ReactComponent as AppleIcon } from "../../../assets/appleLogin.svg";
import useSocialLogin from "../../../hooks/Login/useSocialLogin";
import { GoogleLogin } from "@react-oauth/google"; // Google Login Component
import FacebookLogin from "react-facebook-login"; // Facebook Login Component
import AppleLogin from "react-apple-login"; // Apple Login Component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "../../elements/Button/Button";

const LoginForm = ({ onLoginSuccess, goTo }) => {
  const location = useLocation();

  console.log("goTo", goTo);

  let from;

  if (goTo) {
    from = goTo;
  } else {
    from = location.pathname;
  }

  const {
    handleGoogleLogin,
    handleFacebookLogin,
    handleAppleLogin,
    handleSubmit,
    handleChange,
    isLoading,
  } = useSocialLogin({
    from,
    onLoginSuccess,
    goTo,
  });

  return (
    <form
      onSubmit={handleSubmit}
      className="max-w-md w-full bg-white p-8 rounded-xl shadow-lg pt-[63px] pb-[63px] pl-[65px] pr-[65px]"
    >
      <h1 className="text-left text-roca-h4-5 -600 mb-4 !text-secondary-600">
        Bienvenue dans la famille !
      </h1>

      <h2 className="text-left text-poppins-extra-small mb-6">
        Une communauté de plus de 500 lieux éco-responsables dans toute l'Europe
        ! Faisons de notre maison un meilleur endroit
      </h2>

      <div className="space-y-4">
        <Input
          name="email"
          placeholder="Email"
          onChange={handleChange}
          required
          icon={faEnvelope}
        />

        <Input
          name="password"
          placeholder="Mot de passe"
          onChange={handleChange}
          required
          isShowPassword
          icon={faLock}
        />
      </div>

      <div className="flex justify-between items-center mt-4 mb-6">
        <label className="flex items-center space-x-2 text-primary-600 text-sm">
          <input type="checkbox" className="rounded-full" />
          <span className="text-poppins-extra-small">Se souvenir de moi</span>
        </label>

        <a
          href="/forgot-password"
          className="text-sm !text-primary-600 text-poppins-extra-small"
        >
          Mot de passe oublié ?
        </a>
      </div>
      <div className="flex w-full items-center justify-center">
        <Button colorType="secondary" AddtionnalClasses="w-[220px]">
          {isLoading ? (
            <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
          ) : (
            "Se connecter"
          )}
        </Button>
      </div>

      <p className="mt-6 text-center text-gray-600 text-poppins-extra-small">
        Pas de compte ?{" "}
        <a href="/register" className="text-orange-600 hover:underline">
          Inscrivez-vous ici
        </a>
      </p>

      <div className="mt-6 flex flex-col items-center">
        <p className="text-gray-500 mb-2 text-poppins-extra-small">
          Ou continuer avec
        </p>
        <div className="flex space-x-4">
          {/* Google Login Button */}

          <GoogleLogin
            onSuccess={handleGoogleLogin}
            onError={() => console.log("Erreur lors de la connexion Google")}
            render={(renderProps) => (
              <button
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                className="flex items-center bg-white text-gray-600 border border-gray-300 rounded-full px-4 py-2 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 transition duration-150 ease-in-out"
              >
                <GoogleIcon className="w-6 h-6 mr-2" />{" "}
                {/* Assurez-vous que GoogleIcon est un composant SVG ou une image */}
                <span>Se connecter avec Google</span>
              </button>
            )}
          />

          {/* Facebook Login Button */}
          <FacebookLogin
            appId="1933079873861200"
            autoLoad={false}
            fields="name,email,picture"
            email="email"
            callback={handleFacebookLogin}
            textButton={<FacebookIcon alt="Facebook" />}
            cssClass="btn-facebook"
          />
          {/* Apple Login Button */}
          <AppleLogin
            clientId="TON_CLIENT_ID_APPLE"
            redirectURI="https://tonsiteweb.com/auth/apple/callback"
            callback={handleAppleLogin}
            render={(renderProps) => (
              <AppleIcon alt="Apple" onClick={renderProps.onClick} />
            )}
          />
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
