import { useAuth } from "../../components/Auth/Provider/AuthProvider";
import Swal from "sweetalert2";
import { login } from "../../services/auth.services";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useSocialLogin = ({ from, onLoginSuccess, goTo }) => {
  const { handleLogin } = useAuth();

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false); // Ajoute l'état isLoading

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Commence le chargement
    try {
      const response = await login(formData);
      handleLogin(response.data.token, from);
      Swal.fire({
        icon: "success",
        title: "Connexion réussie !",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        if(goTo) return
        navigate("/");
      });

      if (onLoginSuccess) {
        onLoginSuccess(); // Move to next step after successful login
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Échec de la connexion !",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleGoogleLogin = (tokenResponse) => {
    setIsLoading(true); // Commence le chargement
    try {
      const token = tokenResponse.credential;
      handleLogin(token);
      Swal.fire({
        icon: "success",
        title: "Connexion Google réussie !",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Échec de la connexion Google",
        showConfirmButton: false,
        timer: 1500,
      });
    } finally {
      setIsLoading(false); // Fin du chargement
    }
  };

  const handleFacebookLogin = (response) => {
    setIsLoading(true); // Commence le chargement
    if (response.accessToken) {
      handleLogin(response.accessToken);
      Swal.fire({
        icon: "success",
        title: "Connexion Facebook réussie !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Échec de la connexion Facebook",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setIsLoading(false); // Fin du chargement
  };

  const handleAppleLogin = (response) => {
    setIsLoading(true); // Commence le chargement
    if (response.authorization) {
      handleLogin(response.authorization.id_token);
      Swal.fire({
        icon: "success",
        title: "Connexion Apple réussie !",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Échec de la connexion Apple",
        showConfirmButton: false,
        timer: 1500,
      });
    }
    setIsLoading(false); // Fin du chargement
  };

  return {
    handleGoogleLogin,
    handleFacebookLogin,
    handleAppleLogin,
    handleChange,
    handleSubmit,
    isLoading, // Ajoute isLoading ici
    formData,
    setFormData,
  };
};

export default useSocialLogin;
