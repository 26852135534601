import React, { useState, useEffect } from "react";
import {
  getAllCategoriesAndQuestions,
  createCategory,
  addQuestionInCategory,
  updateQuestion,
  deleteQuestion,
  updateCategory,
  deleteCategory,
} from "../../../services/faq.services";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/Provider/AuthProvider";

const BackOfficeFaq = () => {
  const { user } = useAuth();
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newQuestions, setNewQuestions] = useState({});
  const [newAnswers, setNewAnswers] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  useEffect(() => {
    fetchCategoriesAndQuestions();
  }, []);

  const fetchCategoriesAndQuestions = async () => {
    try {
      const data = await getAllCategoriesAndQuestions();
      setCategories(data);
    } catch (error) {
      console.error("Failed to fetch categories and questions", error);
    }
  };

  const handleAddCategory = async () => {
    if (newCategory.trim() === "") return;

    try {
      await createCategory({ category: newCategory });
      setNewCategory("");
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to add category", error);
    }
  };

  const handleAddQuestion = async (categoryId) => {
    const question = newQuestions[categoryId] || "";
    const answer = newAnswers[categoryId] || "";

    if (question.trim() === "" || answer.trim() === "") return;

    try {
      await addQuestionInCategory(categoryId, {
        question,
        answer,
      });
      setNewQuestions({ ...newQuestions, [categoryId]: "" });
      setNewAnswers({ ...newAnswers, [categoryId]: "" });
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to add question", error);
    }
  };

  const handleQuestionInputChange = (categoryId, value) => {
    setNewQuestions({ ...newQuestions, [categoryId]: value });
  };

  const handleAnswerInputChange = (categoryId, value) => {
    setNewAnswers({ ...newAnswers, [categoryId]: value });
  };

  const handleUpdateQuestion = async (
    categoryId,
    questionId,
    question,
    answer
  ) => {
    try {
      await updateQuestion(questionId, { question, answer });
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to update question", error);
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await deleteQuestion(questionId);
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to delete question", error);
    }
  };

  const handleUpdateCategory = async (categoryId, category) => {
    try {
      await updateCategory(categoryId, { category });
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to update category", error);
    }
  };

  const handleDeleteCategory = async (categoryId) => {
    try {
      await deleteCategory(categoryId);
      fetchCategoriesAndQuestions();
    } catch (error) {
      console.error("Failed to delete category", error);
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-3xl font-bold mb-6">FAQ Management</h1>

      {/* Add New Category */}
      <div className="mb-6">
        <h2 className="text-2xl font-semibold mb-2">Add New Category</h2>
        <input
          type="text"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
          placeholder="New Category Name"
          className="p-2 border rounded mr-2"
        />
        <button
          onClick={handleAddCategory}
          className="px-4 py-2 bg-blue-500 text-white rounded"
        >
          Add Category
        </button>
      </div>

      {/* Categories and Questions */}
      <div>
        {categories.map((category) => (
          <div key={category._id} className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <input
                type="text"
                value={category.category}
                onChange={(e) =>
                  handleUpdateCategory(category._id, e.target.value)
                }
                className="p-2 border rounded mr-2 flex-grow"
              />
              <button
                onClick={() => handleDeleteCategory(category._id)}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Delete Category
              </button>
            </div>
            <ul className="list-disc pl-6">
              {category.questions.map((question) => (
                <li key={question._id} className="mb-4">
                  <div className="flex items-center mb-2">
                    <input
                      type="text"
                      value={question.question}
                      onChange={(e) =>
                        handleUpdateQuestion(
                          category._id,
                          question._id,
                          e.target.value,
                          question.answer
                        )
                      }
                      className="p-2 border rounded mr-2 flex-grow"
                    />
                    <button
                      onClick={() => handleDeleteQuestion(question._id)}
                      className="px-4 py-2 bg-red-500 text-white rounded"
                    >
                      Delete
                    </button>
                  </div>
                  <textarea
                    value={question.answer}
                    onChange={(e) =>
                      handleUpdateQuestion(
                        category._id,
                        question._id,
                        question.question,
                        e.target.value
                      )
                    }
                    className="p-2 border rounded w-full"
                    rows="3"
                  />
                </li>
              ))}
            </ul>

            {/* Add New Question */}
            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Add New Question</h3>
              <input
                type="text"
                value={newQuestions[category._id] || ""}
                onChange={(e) =>
                  handleQuestionInputChange(category._id, e.target.value)
                }
                placeholder="New Question"
                className="p-2 border rounded mr-2"
              />
              <input
                type="text"
                value={newAnswers[category._id] || ""}
                onChange={(e) =>
                  handleAnswerInputChange(category._id, e.target.value)
                }
                placeholder="New Answer"
                className="p-2 border rounded mr-2"
              />
              <button
                onClick={() => handleAddQuestion(category._id)}
                className="px-4 py-2 bg-green-500 text-white rounded"
              >
                Add Question
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BackOfficeFaq;
