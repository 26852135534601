import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faUmbrellaBeach,
  faPaw,
  faWheelchair,
  faTruck,
  faShoppingBag,
  faCalendarCheck,
  faParking,
  faRainbow,
  faLeaf,
  faBicycle,
  faUtensils,
  faChild,
  faMusic,
  faCashRegister,
  faHeart,
  faSnowflake,
  faCouch,
  faTree,
  faStar,
  faChargingStation,
  faFish,
  faBreadSlice,
  faMotorcycle,
  faWineGlass,
  faGlassMartiniAlt,
  faBeer,
  faAppleAlt,
  faSeedling,
  faUsers,
  faChair,
  faGamepad,
  faMicrophone,
  faShip,
  faCloudSun,
  faCarSide,
  faTv,
  faClock,
  faSmoking,
  faBanSmoking,
  faMountain,
  faCoffee,
  faRecycle,
  faPuzzlePiece,
} from "@fortawesome/free-solid-svg-icons";

export const getImageOfTheInfo = (info) => {
  switch (info) {
    case "wifi":
      return {
        name: "Wi-Fi gratuit",
        icon: faWifi,
      };
    case "terrace":
      return {
        name: "Terrasse",
        icon: faUmbrellaBeach,
      };
    case "lgbt":
      return {
        name: "LGBT Friendly",
        icon: faRainbow,
      };
    case "disabled":
      return {
        name: "Accessible aux fauteuils roulants",
        icon: faWheelchair,
      };
    case "pet-friendly":
      return {
        name: "Animaux acceptés",
        icon: faPaw,
      };
    case "vegan":
      return {
        name: "Options véganes",
        icon: faLeaf,
      };
    case "vegetarian":
      return {
        name: "Options végétariennes",
        icon: faLeaf,
      };
    case "organic":
      return {
        name: "Produits bio",
        icon: faSeedling,
      };
    case "parking":
      return {
        name: "Parking",
        icon: faParking,
      };
    case "kids-friendly":
      return {
        name: "Adapté aux enfants",
        icon: faChild,
      };
    case "delivery":
      return {
        name: "Livraison",
        icon: faTruck,
      };
    case "takeaway":
      return {
        name: "À emporter",
        icon: faShoppingBag,
      };
    case "cashless":
      return {
        name: "Paiement sans contact",
        icon: faCashRegister,
      };
    case "live-music":
      return {
        name: "Musique live",
        icon: faMusic,
      };
    case "outdoor-seating":
      return {
        name: "Sièges en extérieur",
        icon: faChair,
      };
    case "family-friendly":
      return {
        name: "Adapté aux familles",
        icon: faUsers,
      };
    case "locally-sourced":
      return {
        name: "Produits locaux",
        icon: faTree,
      };
    case "bike-parking":
      return {
        name: "Parking vélos",
        icon: faBicycle,
      };
    case "eco-friendly":
      return {
        name: "Respectueux de l'environnement",
        icon: faLeaf,
      };
    case "allergen-friendly":
      return {
        name: "Adapté aux allergiques",
        icon: faUtensils,
      };
    case "sports-screen":
      return {
        name: "Diffusion d'événements sportifs",
        icon: faTv,
      };
    case "reservations":
      return {
        name: "Réservation possible",
        icon: faCalendarCheck,
      };
    case "romantic":
      return {
        name: "Atmosphère romantique",
        icon: faHeart,
      };
    case "group-seating":
      return {
        name: "Places pour groupes",
        icon: faUsers,
      };
    case "rooftop":
      return {
        name: "Toit-terrasse",
        icon: faCloudSun,
      };
    case "drive-through":
      return {
        name: "Service au volant",
        icon: faCarSide,
      };
    case "late-night":
      return {
        name: "Ouvert tard",
        icon: faClock,
      };
    case "smoking-area":
      return {
        name: "Zone fumeurs",
        icon: faSmoking,
      };
    case "non-smoking":
      return {
        name: "Non-fumeur",
        icon: faBanSmoking,
      };
    case "gluten-free":
      return {
        name: "Sans gluten",
        icon: faBreadSlice,
      };

    case "charging-stations":
      return {
        name: "Stations de recharge",
        icon: faChargingStation,
      };
    case "fast-service":
      return {
        name: "Service rapide",
        icon: faMotorcycle,
      };
    case "michelin-star":
      return {
        name: "Étoilé Michelin",
        icon: faStar,
      };
    case "private-dining":
      return {
        name: "Salle privée",
        icon: faChair,
      };
    case "luxury":
      return {
        name: "Service de luxe",
        icon: faCouch,
      };
    case "quiet-atmosphere":
      return {
        name: "Atmosphère calme",
        icon: faCouch,
      };
    case "waterfront":
      return {
        name: "Vue sur l'eau",
        icon: faShip,
      };
    case "mountain-view":
      return {
        name: "Vue sur la montagne",
        icon: faMountain,
      };
    case "seafood-specialty":
      return {
        name: "Spécialité fruits de mer",
        icon: faFish,
      };
    case "steakhouse":
      return {
        name: "Spécialité viandes/grillades",
        icon: faUtensils,
      };
    case "buffet":
      return {
        name: "Buffet à volonté",
        icon: faUtensils,
      };
    case "brunch":
      return {
        name: "Brunch",
        icon: faCoffee,
      };
    case "afternoon-tea":
      return {
        name: "Thé de l'après-midi",
        icon: faCoffee,
      };
    case "wine-bar":
      return {
        name: "Bar à vins",
        icon: faWineGlass,
      };
    case "cocktail-bar":
      return {
        name: "Bar à cocktails",
        icon: faGlassMartiniAlt,
      };
    case "beer-garden":
      return {
        name: "Biergarten",
        icon: faBeer,
      };
    case "café":
      return {
        name: "Café",
        icon: faCoffee,
      };
    case "bakery":
      return {
        name: "Boulangerie",
        icon: faBreadSlice,
      };
    case "healthy-food":
      return {
        name: "Cuisine santé",
        icon: faAppleAlt,
      };
    case "fusion-cuisine":
      return {
        name: "Cuisine fusion",
        icon: faUtensils,
      };
    case "street-food":
      return {
        name: "Cuisine de rue",
        icon: faUtensils,
      };
    case "food-truck":
      return {
        name: "Camion de restauration",
        icon: faTruck,
      };
    case "sustainable":
      return {
        name: "Pratiques durables",
        icon: faLeaf,
      };
    case "zero-waste":
      return {
        name: "Zéro déchet",
        icon: faRecycle,
      };
    case "communal-seating":
      return {
        name: "Places communes",
        icon: faUsers,
      };
    case "game-room":
      return {
        name: "Salle de jeux",
        icon: faGamepad,
      };
    case "karaoke":
      return {
        name: "Karaoké",
        icon: faMicrophone,
      };
    case "escape-room":
      return {
        name: "Escape room",
        icon: faPuzzlePiece,
      };
    case "brewery":
      return {
        name: "Brasserie",
        icon: faBeer,
      };
    default:
      return { name: "", icon: "" };
  }
};
