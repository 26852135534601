import React, { useState } from "react";
import AddEtiquette from "../../AddEtiquette";

const FormLaClefVertSensibilisationClient = () => {
  const [criteres, setCriteres] = useState({
    labellisation: false,
    ecoGestes: false,
    mobiliteDurable: false,
    informationsFauneFlore: false,
    questionnaireSatisfaction: false,
    menuBio: false,
  });

  const [labellisationDetails, setLabellisationDetails] = useState([]);
  const [ecoGestes, setEcoGestes] = useState([]);
  const [mobiliteDurable, setMobiliteDurable] = useState([]);
  const [informationsFauneFlore, setInformationsFauneFlore] = useState([]);
  const [questionnaires, setQuestionnaires] = useState([]);
  const [menuBioDetails, setMenuBioDetails] = useState([]);
  const [initiativesActivity, setInitiativesActivity] = useState([]);

  const handleAdd = (field, data) => {
    switch (field) {
      case "labellisation":
        setLabellisationDetails((prev) => [...prev, data]);
        break;
      case "ecoGestes":
        setEcoGestes((prev) => [...prev, data]);
        break;
      case "mobiliteDurable":
        setMobiliteDurable((prev) => [...prev, data]);
        break;
      case "informationsFauneFlore":
        setInformationsFauneFlore((prev) => [...prev, data]);
        break;
      case "questionnaires":
        setQuestionnaires((prev) => [...prev, data]);
        break;
      case "menuBio":
        setMenuBioDetails((prev) => [...prev, data]);
        break;
      case "initiativesActivity":
        setInitiativesActivity((prev) => [...prev, data]);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formulaire soumis :", {
      labellisationDetails,
      ecoGestes,
      mobiliteDurable,
      informationsFauneFlore,
      questionnaires,
      menuBioDetails,
      initiativesActivity,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <h1>Sensibilisation de la clientèle à l'environnement</h1>

      {/* Critères 14 à 19 */}
      {[
        {
          id: "labellisation",
          title: "14. Labellisation Clef Verte (Impératif)",
          description:
            "La labellisation Clef Verte est mise en valeur à l'accueil et dans vos outils de communication.",
          fields: [
            {
              name: "tool",
              label: "Outil de communication",
              type: "text",
              placeholder: "Nom de l'outil (site web, brochure, etc.)",
            },
            {
              name: "description",
              label: "Description",
              type: "text",
              placeholder: "Description de la mise en valeur",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
        {
          id: "ecoGestes",
          title: "15. Diffusion d'écogestes (Impératif)",
          description:
            "Vous diffusez des écogestes incitant les clients à économiser l'eau et l'énergie, et à limiter le gaspillage alimentaire et les déchets.",
          fields: [
            {
              name: "description",
              label: "Description de l'écogeste",
              type: "text",
              placeholder: "Exemple : Réduire la consommation d'eau",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
        {
          id: "mobiliteDurable",
          title: "16. Mobilité durable (Impératif)",
          description:
            "Vous encouragez la mobilité durable pour vos clients et vos employés.",
          fields: [
            {
              name: "description",
              label: "Description de l'initiative",
              type: "text",
              placeholder: "Description de l'initiative",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
        {
          id: "informationsFauneFlore",
          title: "17. Informations sur la Faune et Flore (Impératif)",
          description:
            "Vous mettez à disposition des clients des informations sur la faune, la flore, et les sites naturels alentour.",
          fields: [
            {
              name: "description",
              label: "Description",
              type: "text",
              placeholder: "Description des informations fournies",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
        {
          id: "questionnaireSatisfaction",
          title: "18. Questionnaire de satisfaction (Conseillé)",
          description:
            "Vous diffusez un questionnaire de satisfaction comprenant au minimum 3 questions sur vos actions environnementales.",
          fields: [
            {
              name: "description",
              label: "Description du questionnaire",
              type: "text",
              placeholder:
                "Exemple : Questionnaire client sur les actions environnementales",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
        {
          id: "menuBio",
          title: "19. Menu bio et produits locaux (Conseillé)",
          description:
            "Vous indiquez sur le menu ou au buffet les produits bios, éco-labellisés, issus du commerce équitable, et/ou locaux.",
          fields: [
            {
              name: "description",
              label: "Description",
              type: "text",
              placeholder: "Description des produits indiqués sur le menu",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
        {
          id: "initiativesActivity",
          title: "20. Mise en oeuvre d'initiatives durables (Conseillé)",
          description:
            "L’établissement met en œuvre au moins 2 initiatives durables relatives aux animations et activités à destination des clients.",
          fields: [
            {
              name: "description",
              label: "Description",
              type: "text",
              placeholder: "Description des initiatives durables",
            },
            {
              name: "pj",
              label: "Pièce jointe",
              type: "file",
            },
          ],
        },
      ].map((critere) => (
        <fieldset key={critere.id} className="border p-4 rounded">
          <legend className="font-bold">{critere.title}</legend>
          <p className="text-gray-600 mb-2">{critere.description}</p>
          <div className="flex items-center space-x-4">
            <label className="flex items-center">
              <input
                type="radio"
                name={critere.id}
                value="oui"
                onChange={() =>
                  setCriteres((prev) => ({ ...prev, [critere.id]: true }))
                }
                className="mr-2"
              />
              Oui
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name={critere.id}
                value="non"
                onChange={() =>
                  setCriteres((prev) => ({ ...prev, [critere.id]: false }))
                }
                className="mr-2"
              />
              Non
            </label>
          </div>
          {criteres[critere.id] && (
            <AddEtiquette
              title={`Ajouter des détails pour : ${critere.title}`}
              fields={critere.fields}
              onAdd={(data) => handleAdd(critere.id, data)}
            />
          )}
        </fieldset>
      ))}

      <button
        type="submit"
        className="bg-primary-600 text-white px-4 py-2 rounded hover:bg-primary-700"
      >
        Soumettre
      </button>
    </form>
  );
};

export default FormLaClefVertSensibilisationClient;
