import React, { useState } from "react";
import WhatIsTheAction from "../WhatIsTheAction";
import { useParams } from "react-router-dom";
import useEstablishment from "../../../../hooks/establishment/useEstablishment";
import useLabels from "../../../../hooks/labels/useLabels";
import FormLaClefVerteWithAccordion from "./forms/LaClefVerte/FormLaClefVerteWithAccordion";
import FormGreenFood from "./forms/GreenFood/FormGreenFood";

// Importez vos composants de formulaires ici

const RequestLabel = () => {
  const { establishmentId } = useParams();
  const { establishment } = useEstablishment({ establishmentId });
  const { labels } = useLabels();

  console.log("labels", labels);

  const [selectedLabel, setSelectedLabel] = useState("");

  console.log("selectedLabel", selectedLabel);

  const labelForms = {
    ecotable: null,
    la_clef_verte: FormLaClefVerteWithAccordion,
    green_food: FormGreenFood,
    bon_pour_le_climat: null,
    v_label: null,
    fig: null,
    framheim: null,
    ab_bio: null,
    ecolabel_eur: null,
    ethic_ocean: null,
    asc_aquaculture: null,
  };

  const SelectedForm = selectedLabel ? labelForms[selectedLabel] : null;

  return (
    <>
      <WhatIsTheAction actionIs={"requestLabel"} name={establishment?.name} />

      <div className="container mx-auto flex flex-col bg-white justify-center rounded-20 pl-14 pr-14 pb-10 shadow-lg">
        <h1 className="text-roca-h4-5 !text-secondary-600 text-center mt-5 mb-5">
          Dépot de demande de label pour {establishment?.name}
        </h1>

        <select
          name="label"
          id="label"
          value={selectedLabel}
          onChange={(e) => setSelectedLabel(e.target.value)}
          className="border border-gray-300 rounded-lg p-2 mb-6"
        >
          <option value="" disabled>
            Sélectionnez un label
          </option>
          {labels.map((label) => (
            <option key={label._id} value={label.slugLabel}>
              {label.name}
            </option>
          ))}
        </select>

        {SelectedForm ? (
          <SelectedForm establishmentId={establishmentId} />
        ) : (
          <p className="text-gray-500">
            Veuillez sélectionner un label pour afficher le formulaire
            correspondant.
          </p>
        )}
      </div>
    </>
  );
};

export default RequestLabel;
