import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const SelectFilter = ({ value, options, onChange, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    options.find((option) => option.value === value)?.name || placeholder
  );

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedOption(option.name);
    onChange(option.label);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full inline-block text-left" ref={dropdownRef}>
      <div
        className="flex items-center justify-between cursor-pointer bg-white text-gray-800 border border-primary-600 rounded-full px-4 py-2"
        onClick={toggleDropdown}
      >
        <span>{selectedOption}</span>
        <FontAwesomeIcon icon={faChevronDown} className="text-gray-600" />
      </div>

      {isOpen && (
        <div className="absolute mt-1 w-auto h-56 overflow-auto bg-white shadow-lg rounded-lg z-10">
          <div
            className="px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-lg"
            onClick={() => handleSelect({ name: placeholder, value: "" })}
          >
            {placeholder}
          </div>

          {options.map((option) => (
            <div
              key={option.value}
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 rounded-lg"
              onClick={() => handleSelect(option)}
            >
              {option.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectFilter;
